<template>
  <div class="ui-radio-btn">
    <input
      :id="id || value"
      class="ui-radio-btn-input"
      type="radio"
      :value="value"
      :name="name"
      @change="onChange"
      :checked="isChecked"
      :disabled="disabled"
    />
    <label
      class="ui-radio-btn-label"
      :class="[size, color, customClass, disabled ? 'disabled' : '']"
      :for="id || value"
      :style="{ width }"
    >
      <Typography
        text-align="center"
        :color="disabled ? (isChecked ? 'disabled' : 'grey25') : 'inherit'"
        :size="typographySize"
        :disabled="disabled || undefined"
        class="ui-typography"
        :class="[customClass]"
      >
        {{ label }}
      </Typography>
    </label>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import Typography from '@/components/ui/Typography';

export default defineComponent({
  name: 'RadioButton',
  components: { Typography },
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    id: String,
    value: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 's'
    },
    color: {
      type: String,
      default: ''
    },
    custom: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: String
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const onChange = ({ target: { value } }) => {
      emit('update:modelValue', value);
    };
    const isChecked = computed(() => props.modelValue === props.value);
    return {
      onChange,
      isChecked,
      customClass: computed(() => (props.custom ? 'custom' : '')),
      typographySize: computed(() => (props.size === 'm' ? 'm' : 's'))
    };
  }
});
</script>

<style lang="scss">
@import '@/styles/effect';
@import '@/styles/spacing';

.ui-radio-btn {
  height: inherit;

  .ui-radio-btn-input {
    display: none;
  }

  .ui-radio-btn-label {
    color: var(--primary-color);
    background: var(--dark-10-color);
    min-width: 60px;
    height: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover:not(.disabled) {
      cursor: pointer;
    }

    &:active:not(.disabled) {
      opacity: var(--action-opacity);
    }

    &.m {
      padding: 0 $spacing-base * 4;
    }

    &.s {
      padding: 0 $spacing-base * 4;
    }

    &.xs {
      padding: 0 $spacing-base * 3;
    }
    &.custom {
      height: unset;
      padding: $spacing-base $spacing-base;
    }

    &.dark {
      color: var(--dark-color);
    }

    &.disabled {
      background: var(--background-light-grey);
    }
  }

  .ui-radio-btn-input:checked + .ui-radio-btn-label {
    background: var(--primary-color);
    color: var(--contrast-color);
    &.disabled {
      background: var(--background-disabled-color);
    }
    &.custom {
      background: var(--dark-10-color);
    }
    .ui-typography {
      &.custom {
        background: var(--primary-color);
      }
    }
  }

  .ui-radio-btn-input:checked + .ui-radio-btn-label.dark {
    background: var(--dark-color);
  }

  .ui-typography {
    &.custom {
      width: 80px;
      padding: 6px 8px;
      border-radius: 4px;
    }
  }
}
</style>
