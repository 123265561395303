import { Ref, ref } from 'vue';

interface checkBoxReturn<T> {
  checked: Ref<Array<T>>;
  isCheckedAll: Ref<boolean>;
  uncheckAll: (data: Array<T>) => void;
  checkAll: (data: Array<T>) => void;
  checkHandler: (data: T, checked: boolean, allLength: number) => void;
  toggleAll: (data: Array<T>) => void;
}

const useCheckBox = <T extends { checked: boolean }>(): checkBoxReturn<T> => {
  const checked = ref<Array<T>>([]) as Ref<Array<T>>;
  const isCheckedAll = ref<boolean>(false);

  const uncheckAll = (data: Array<T>) => {
    checked.value = [];
    isCheckedAll.value = false;
    data.map(value => (value.checked = false));
  };
  const checkAll = (data: Array<T>) => {
    checked.value = data;
    isCheckedAll.value = true;
    data.map(value => (value.checked = true));
  };
  const check = (data: T, allLength: number) => {
    checked.value = [...checked.value, data];
    if (checked.value.length === allLength) isCheckedAll.value = true;
  };

  const uncheck = (data: T) => {
    checked.value = checked.value.filter(value => value !== data);
    isCheckedAll.value = false;
  };

  const checkHandler = (data: T, checked: boolean, allLength: number) => {
    if (checked) check(data, allLength);
    else uncheck(data);
  };

  const toggleAll = (data: Array<T>) => {
    if (isCheckedAll.value) {
      checkAll(data);
    } else {
      uncheckAll(data);
    }
  };

  return {
    checked,
    isCheckedAll,
    checkHandler,
    checkAll,
    uncheckAll,
    toggleAll
  };
};

export default useCheckBox;
