<template>
  <div
    :style="{
      overflowY: maxHeight !== '' ? 'auto' : '',
      maxHeight,
      minHeight
    }"
    :class="{ 'fix-header': fixedHeader, 'fix-left-column': fixedLeftColumn }"
  >
    <table class="ui-datalist">
      <thead class="ui-datalist-header">
        <tr>
          <th
            v-for="header in headers"
            :key="header.label"
            :style="{
              whiteSpace: 'nowrap',
              width: header.width,
              minWidth: header.minWidth,
              textAlign: header.textAlign,
              paddingLeft: header.paddingLeft
            }"
            class="ui-datalist-content py-2"
          >
            <Typography size="s" bold>{{ header.label }}</Typography>
          </th>
        </tr>
      </thead>
      <template v-if="loading">
        <tbody>
          <tr>
            <td :colspan="headers.length">
              <Flex justify-center class="mt-6">
                <Loading />
              </Flex>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-else-if="items.length === 0">
        <tbody>
          <tr>
            <td :colspan="headers.length">
              <Typography text-align="center" class="mt-6">
                {{ emptyMessage }}
              </Typography>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-else>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.value"
            class="ui-datalist-item"
            :class="[
              getSelectedClass(item.id),
              getWarningClass(item.id),
              { 'is-hover': hover }
            ]"
            @click="$emit('click-item', item.id || index)"
          >
            <td
              v-for="header in headers"
              :key="header.id"
              :style="{
                width: header.width,
                height: height,
                textAlign: header.textAlign
              }"
              :class="[
                spacingClass,
                getHighlightClass(item.id),
                { 'fix-left-column': fixedLeftColumn }
              ]"
              class="ui-datalist-content"
            >
              <slot :name="header.id" :item="item[header.id]" :index="index">
                <template v-if="typeof item[header.id] === 'string'">
                  <TypographyEllipsis
                    v-if="isEllipsis"
                    class="ui-datalist-content-title"
                    line-height="1.5"
                    size="s"
                    >{{ item[header.id] }}
                  </TypographyEllipsis>
                  <Typography v-else line-height="1.5" size="s">
                    {{ item[header.id] }}
                  </Typography>
                </template>
                <template v-else>
                  <Typography line-height="1.5" size="s">
                    {{ item[header.id] }}
                  </Typography>
                </template>
              </slot>
            </td>
          </tr>
        </tbody>
      </template>
    </table>
  </div>
</template>
<script>
import { computed, defineComponent } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Loading from '@/components/ui/Loading.vue';
import Typography from '@/components/ui/Typography.vue';
import TypographyEllipsis from './TypographyEllipsis.vue';

export default defineComponent({
  name: 'DataList',
  components: {
    Flex,
    Loading,
    Typography,
    TypographyEllipsis
  },
  props: {
    /**
     * {
     *  id: string;
     *  label: string;
     *  width: string;
     *  textAlign: string;
     * }[]
     */
    headers: Array,
    /**
     * {
     *  id?: number
     *  [headers[*].id]: string | object;
     * }[]
     */
    items: Array,
    /**
     * spacing
     * l ... py-6
     * m ... py-4
     * s ... py-2
     * ss ... py-1
     */
    spacing: {
      type: String,
      default: 'm'
    },
    highlightIds: {
      type: Array,
      default: () => []
    },
    warningIds: {
      type: Array,
      default: () => []
    },
    selectedIds: {
      type: Array,
      default: () => []
    },
    newItemIds: {
      type: Array,
      default: () => []
    },
    hover: Boolean,
    height: {
      type: String,
      default: ''
    },
    emptyMessage: {
      type: String,
      default: 'データがありません'
    },
    loading: Boolean,
    isEllipsis: {
      type: Boolean,
      default: false
    },
    fixedHeader: {
      type: Boolean,
      default: false
    },
    fixedLeftColumn: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: String,
      default: ''
    }
  },
  emits: ['click-item'],
  setup(props) {
    const spacingClass = computed(() => {
      const { spacing } = props;
      let spacingClass = 'py-4';
      if (spacing === 'l') {
        spacingClass = 'py-6';
      } else if (spacing === 's') {
        spacingClass = 'py-2';
      } else if (spacing === 'ss') {
        spacingClass = 'py-1';
      }
      return spacingClass;
    });

    const getHighlightClass = itemId => {
      if (props.highlightIds.includes(itemId)) {
        return 'highlight';
      } else if (props.newItemIds.includes(itemId)) {
        return 'new-item';
      } else {
        return '';
      }
    };

    const getWarningClass = itemId => {
      if (props.warningIds.includes(itemId)) {
        return 'warning';
      } else {
        return '';
      }
    };

    const getSelectedClass = itemId =>
      props.selectedIds.includes(itemId) ? 'selected' : '';

    return {
      spacingClass,
      getHighlightClass,
      getWarningClass,
      getSelectedClass
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';
@import '@/styles/effect';

.ui-datalist {
  border-collapse: collapse;
  width: 100%;
}

.fix-header {
  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }
}

.fix-left-column {
  font-size: 12px;

  .ui-datalist-header {
    .ui-datalist-content {
      &:nth-of-type(1) {
        position: sticky;
        left: 0;
        background-color: #f6f6f6;
        white-space: nowrap;
      }
    }
  }

  &:nth-of-type(1) {
    position: sticky;
    left: 0;
    background-color: #fff;
    white-space: nowrap;
  }
}

.ui-datalist-header {
  text-align: left;

  tr {
    background-color: #fff;
  }

  th {
    background-color: var(--dark-5-color);
  }
}

.ui-datalist-item {
  &:not(:last-child) {
    border-bottom: 1px solid var(--primary-light-color);
  }

  &.is-hover {
    @extend .hover-background;

    &:active:not(:disabled) {
      opacity: var(--action-opacity);
    }
  }

  &.selected {
    background-color: var(--primary-light-color);

    .ui-typography {
      color: var(--primary-color);
    }

    &.is-hover:hover {
      background-color: var(--primary-light-color);
    }
  }

  &.warning {
    color: var(--error-color);
    .ui-typography {
      color: var(--error-color);
    }
  }
}

.ui-datalist-content {
  padding-left: $spacing-base * 4;
  padding-right: $spacing-base * 4;

  &-title {
    width: fit-content;
    max-width: 500px;
  }
}

.highlight {
  background-color: var(--tertiary-light-color);
}

.new-item {
  background-color: var(--tertiary-light-color);
}
</style>
