/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TroProgramBroadcast,
  TroProgramBroadcastTroProgramTypeEnum
} from '@/api/openapi';
import { DATE_FORMAT } from '@/common/format';
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  format,
  subDays
} from 'date-fns';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { computed, ref } from 'vue';

const convertToDate = (hhmm: string, isAdd: boolean): Date => {
  let hh = Number(hhmm.split(':')[0]);
  const mm = Number(hhmm.split(':')[1]);
  const addDate: number = isAdd && hh >= 24 ? 1 : 0;
  hh = hh >= 24 ? hh - 24 : hh;
  return new Date(2000, 10, 10 + addDate, hh, mm);
};

const getRangeListTypeImport = (start: string, end: string) => {
  const rangeStart = convertToDate(start, true);
  const rangeEnd = convertToDate(end, true);
  return getRangeList(rangeStart, rangeEnd);
};

const getRangeListTypeSelect = (
  start: string,
  end: string,
  isMatchDataDayOfWeek
) => {
  const startHH = Number(start.split(':')[0]);
  const startMM = Number(start.split(':')[1]);
  const endHH = Number(end.split(':')[0]);
  const endMM = Number(end.split(':')[1]);
  const isAfterStart =
    startHH > endHH || (startHH === endHH && startMM > endMM);
  const isAdd = !isAfterStart && isMatchDataDayOfWeek;
  const rangeStart = convertToDate(start, isAdd);
  const rangeEnd = convertToDate(end, isAdd);
  return getRangeList(rangeStart, rangeEnd);
};

const getRangeList = (start: Date, end: Date) => {
  const rangeLength = differenceInMinutes(end, start);
  const rangeList = [start];
  for (let i = 0; i < rangeLength; i++) {
    rangeList.push(addMinutes(rangeList[i], 1));
  }
  return rangeList.map(v => v.toLocaleString());
};
const checkSplitTimeRange = (
  selectList: {
    broadcastDate: string;
    dayOfWeek: string;
    startTime: string;
    endTime: string;
    isExcludeBroadcast: boolean;
  }[],
  importDataDayOfWeek: string,
  importDataStartTime: string,
  importDataEndTime: string
): boolean => {
  const importDataRange = getRangeListTypeImport(
    importDataStartTime,
    importDataEndTime
  );
  const selectRangeList = selectList.map(v => {
    const isMatchDataDayOfWeek = importDataDayOfWeek === v.dayOfWeek;
    return getRangeListTypeSelect(v.startTime, v.endTime, isMatchDataDayOfWeek);
  });
  let timeList: string[] = importDataRange;
  selectRangeList.map(v => {
    timeList = timeList.filter(time => !v.includes(time));
  });
  if (timeList.length == 0) return false;
  let judge = false;
  timeList.map((time, i) => {
    if (i != timeList.length - 1 && !judge) {
      const t = new Date(time);
      const after = addMinutes(t, 1);
      if (after.toLocaleString() != timeList[i + 1]) {
        judge = true;
      }
    }
  });
  return judge;
};

export const useProgramBroadcastData = (
  broadcastList: TroProgramBroadcast[] | undefined,
  excludedBroadcasts: number[],
  isBroadcastTimeType: boolean,
  importDataDayOfWeek: string,
  importDataStartTime: string,
  importDataEndTime: string
): any => {
  const localizeTroPlacementStatus = (
    value: TroProgramBroadcastTroProgramTypeEnum | undefined
  ) => {
    switch (value) {
      case TroProgramBroadcastTroProgramTypeEnum.Regular:
        return 'レギュラー';
      case TroProgramBroadcastTroProgramTypeEnum.Single:
        return '単発';
      case TroProgramBroadcastTroProgramTypeEnum.Special:
        return 'スペシャル';
      case TroProgramBroadcastTroProgramTypeEnum.Promotion:
        return '番宣';
      case TroProgramBroadcastTroProgramTypeEnum.Rebroadcasting:
        return '再放送';
      default:
        return 'レギュラー';
    }
  };

  const timeFormat = (time: string) => {
    const arr = time.split(':');
    return arr[0] + ':' + arr[1];
  };

  const differenceTime = (start: string, end: string): number => {
    const startH = Number(start.split(':')[0]);
    const startM = Number(start.split(':')[1]);
    const endH = Number(end.split(':')[0]);
    const endM = Number(end.split(':')[1]);
    const startSum = startH * 60 + startM;
    let endSum = endH * 60 + endM;
    if (startSum > endSum) {
      endSum = endSum + 24 * 60;
    }
    return endSum - startSum;
  };

  const base = broadcastList
    ? broadcastList.map(v => ({
        ...v,
        time: timeFormat(v.startTime) + '〜' + timeFormat(v.endTime),
        airTime: differenceTime(v.startTime, v.endTime),
        isExcludeBroadcast: excludedBroadcasts.includes(v.id)
      }))
    : [];
  const broadcastDateList = base?.map(v => v.broadcastDate);
  const dayList = broadcastDateList?.filter(
    (v, index) => broadcastDateList.indexOf(v) === index
  );
  // 放送時間を固定がtrueの場合のテーブルデータ
  const fixedBroadcastTimeTableData = dayList.map(day => {
    return {
      broadcastDate: day,
      id: base.filter(v => v.broadcastDate == day).map(v => v.id),
      formatBroadcastDate: new Date(day),
      dayOfWeek: base.filter(v => v.broadcastDate == day)[0].dayOfWeek,
      airTime: base.filter(v => v.broadcastDate == day).map(v => v.airTime),
      time: base.filter(v => v.broadcastDate == day).map(v => v.time),
      programName: base
        .filter(v => v.broadcastDate == day)
        .map(v => v.programName),
      troProgramType: base
        .filter(v => v.broadcastDate == day)
        .map(v => v.troProgramType),
      isExcludeBroadcast: base
        .filter(v => v.broadcastDate == day)
        .some(v => excludedBroadcasts.includes(v.id))
    };
  });

  const convertTableData = ref(
    isBroadcastTimeType
      ? fixedBroadcastTimeTableData
      : broadcastList
      ? broadcastList.map(v => ({
          ...v,
          formatBroadcastDate: new Date(v.broadcastDate),
          time: timeFormat(v.startTime) + '〜' + timeFormat(v.endTime),
          airTime: differenceTime(v.startTime, v.endTime),
          isExcludeBroadcast: excludedBroadcasts.includes(v.id)
        }))
      : []
  );

  const programTypeFilterItems = computed(() => {
    const list = broadcastList?.map(v => v.troProgramType);
    return list?.filter((v, index) => list.indexOf(v) === index);
  });

  // 飛び地の判定
  // インポードデータの時間を1分単位で配列化。対象外の時間も同様に配列化。
  // インポードデータの配列から対象外の配列との重複を削除し、残った値が連続しているかを判定
  const splitTimeRangeList = computed(() => {
    if (isBroadcastTimeType) return [];
    const baseList = convertTableData.value
      ?.map(v => ({
        broadcastDate: v.broadcastDate,
        dayOfWeek: v.dayOfWeek,
        startTime: v.startTime,
        endTime: v.endTime,
        isExcludeBroadcast: v.isExcludeBroadcast
      }))
      ?.filter(v => v.isExcludeBroadcast);
    const tmpDayList = baseList?.map(v => v.broadcastDate);
    const dayList = tmpDayList?.filter(
      (v, index) => tmpDayList.indexOf(v) === index
    );
    const list = dayList.filter(day => {
      const subDay = format(subDays(new Date(day), 1), DATE_FORMAT);
      const addDay = format(addDays(new Date(day), 1), DATE_FORMAT);
      return checkSplitTimeRange(
        baseList.filter(
          v =>
            v.broadcastDate == day ||
            v.broadcastDate == subDay ||
            v.broadcastDate == addDay
        ),
        importDataDayOfWeek,
        importDataStartTime,
        importDataEndTime
      );
    });
    const errorList: any[] = [];
    const checkList = convertTableData.value?.map(v => v.broadcastDate);
    list.map(date => {
      errorList.push(date);
      const subDay = format(subDays(new Date(date), 1), DATE_FORMAT);
      if (checkList.includes(subDay)) errorList.push(subDay);
      const addDay = format(addDays(new Date(date), 1), DATE_FORMAT);
      if (checkList.includes(addDay)) errorList.push(addDay);
    });
    return errorList;
  });
  return {
    convertTableData,
    localizeTroPlacementStatus,
    programTypeFilterItems,
    splitTimeRangeList
  };
};

// 飛び地の判定(データテーブル用)
export const judgeSplitTimeRange = (
  data: TroProgramBroadcast[] | undefined,
  excludedBroadcasts: number[],
  importDataDayOfWeek: string,
  importDataStartTime: string,
  importDataEndTime: string,
  exclude: boolean
): boolean => {
  if (!data || exclude) return false;
  const customData = data.map(v => ({
    ...v,
    isExcludeBroadcast: excludedBroadcasts.includes(v.id)
  }));
  const baseList = customData
    ?.map(v => ({
      broadcastDate: v.broadcastDate,
      dayOfWeek: v.dayOfWeek,
      startTime: v.startTime,
      endTime: v.endTime,
      isExcludeBroadcast: v.isExcludeBroadcast
    }))
    ?.filter(v => v.isExcludeBroadcast);
  let isError = false;
  baseList.map(day => {
    const subDay = format(subDays(new Date(day.broadcastDate), 1), DATE_FORMAT);
    const addDay = format(addDays(new Date(day.broadcastDate), 1), DATE_FORMAT);
    const is = checkSplitTimeRange(
      baseList.filter(
        v =>
          v.broadcastDate == day.broadcastDate ||
          v.broadcastDate == subDay ||
          v.broadcastDate == addDay
      ),
      importDataDayOfWeek,
      importDataStartTime,
      importDataEndTime
    );
    if (is) isError = true;
  });
  return isError;
};

export const useDataTable = (): any => {
  const textFilterMatchModes = ref([
    { label: '等しい', value: FilterMatchMode.EQUALS },
    { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
    { label: '含む', value: FilterMatchMode.CONTAINS },
    { label: '含まない', value: FilterMatchMode.NOT_CONTAINS }
  ]);
  const numberFilterMatchModes = ref([
    { label: '等しい', value: FilterMatchMode.EQUALS },
    { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
    { label: '以上', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
    { label: '以下', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    { label: 'より大きい', value: FilterMatchMode.GREATER_THAN },
    { label: 'より小さい', value: FilterMatchMode.LESS_THAN }
  ]);
  const dateFilterMatchModes = ref([
    { label: '等しい', value: FilterMatchMode.DATE_IS },
    { label: '含まない', value: FilterMatchMode.DATE_IS_NOT },
    { label: '以前', value: FilterMatchMode.DATE_BEFORE },
    { label: 'より後', value: FilterMatchMode.DATE_AFTER }
  ]);
  const filters = ref({
    formatBroadcastDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
    },
    dayOfWeek: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    time: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    airTime: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    programName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    troProgramType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    isExcludeBroadcast: { value: null, matchMode: FilterMatchMode.IN }
  });

  return {
    textFilterMatchModes,
    numberFilterMatchModes,
    dateFilterMatchModes,
    filters
  };
};

export const getBroadcastIds = (
  broadcastList: TroProgramBroadcast[] | undefined,
  importDataStartTime: string,
  importDataEndTime: string,
  isExcluded: boolean
): number[] => {
  const base = broadcastList ?? [];
  const broadcastDateList = base?.map(v => v.broadcastDate);
  const dayList = broadcastDateList?.filter(
    (v, index) => broadcastDateList.indexOf(v) === index
  );
  const data = dayList?.map(day => {
    return {
      ids: base.filter(v => v.broadcastDate == day).map(v => v.id),
      troProgramTypeList: base
        .filter(v => v.broadcastDate == day)
        .map(v => v.troProgramType)
    };
  });

  const broadcastIds: number[] = [];
  const excludedBroadcastsIds: number[] = [];
  data.map(v => {
    if (
      v.troProgramTypeList.includes(
        TroProgramBroadcastTroProgramTypeEnum.Single
      )
    ) {
      v.ids.map(id => {
        excludedBroadcastsIds.push(id);
      });
    } else {
      v.ids.map(id => {
        broadcastIds.push(id);
      });
    }
  });

  return isExcluded ? excludedBroadcastsIds : broadcastIds;
};
