/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TroApi } from '@/api';
import { TroProjectForm } from '@/api/openapi';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { SubmitType } from '@/composables/planning/tro/inputForm';
import { PLANNING_ROUTES, ROUTE_NAMES } from '@/router';
import Axios from 'axios';
import { Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

type createProjectReturnTypes = {
  projectSearch: (projectId: number) => Promise<unknown>;
  createProject: (values: any) => Promise<void>;
  isCreatingProject: Ref<boolean>;
};

export const useCreateProject = (): createProjectReturnTypes => {
  const router = useRouter();
  const route = useRoute();
  const companyId = route.params.companyId.toString();

  if (!companyId) {
    toast({ title: 'カンパニーが選択されていません', variant: 'error' });
  }

  const projectSearch = async (projectId: number) => {
    try {
      const res = await TroApi.postCmPlanningTroProjectsTroProjectIdTroSearch(
        projectId
      );
      if (200 <= res.status && res.status < 300) {
        toast({
          title: '成功',
          message: 'プロジェクトの実行に成功しました',
          variant: 'success'
        });
        return res;
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの実行に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      handleError(e, 'プロジェクトの実行に失敗しました', '');
    }
  };

  const _createProject = async ({
    form,
    submitType,
    isForce = false
  }: {
    form: TroProjectForm;
    submitType: SubmitType;
    isForce: boolean;
  }) => {
    try {
      const res = await TroApi.postCmPlanningCompaniesCompanyIdTroProjects(
        Number(companyId),
        form
      );
      if (200 <= res.status && res.status < 300) {
        toast({
          title: '成功',
          message: 'プロジェクトの作成に成功しました',
          variant: 'success'
        });
        if (submitType == SubmitType.save) {
          await router.push({
            name: PLANNING_ROUTES.tro,
            params: route.params
          });
        } else {
          const projectId = res.data.basicInfo.projectId ?? 0;
          const searchRes = await projectSearch(projectId);
          if (searchRes) {
            if (!isForce) {
              await router.push({
                name: PLANNING_ROUTES.tro,
                params: route.params
              });
            } else {
              await router.push({
                name: PLANNING_ROUTES.edit,
                params: { ...route.params, projectId: projectId }
              });
            }
          }
        }
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの作成に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e);
      } else if (e.status === 403) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの作成に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの作成に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isCreatingProject, createProject] = useLoading(_createProject);

  return {
    projectSearch,
    createProject,
    isCreatingProject
  };
};
