
import { computed, defineComponent, ref, watch } from 'vue';
import RadioButton from '@/components/ui/RadioButton.vue';
import Tooltip from '@/components/ui/Tooltip.vue';

const __default__ = defineComponent({
  name: 'RadioButtonGroup',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    buttons: {
      type: [Object],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    size: {
      type: String, // m | s | xs
      default: 'm'
    },
    custom: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: String,
    width: String
  },
  components: { RadioButton, Tooltip },
  setup(props, { emit }) {
    const val = ref(props.modelValue);
    const onChange = (event: { target: HTMLInputElement }) => {
      emit('update:modelValue', event.target.value);
    };
    const radioGroupRadius = props.custom ? '4px' : '20px';
    watch(
      () => props.modelValue,
      () => (val.value = props.modelValue)
    );
    return {
      val,
      customClass: computed(() => (props.custom ? 'custom' : '')),
      onChange,
      radioGroupRadius
    };
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "f61d5754": (_ctx.radioGroupRadius)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__