import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ui-radio-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
      return (_openBlock(), _createElementBlock("div", {
        key: button.id,
        class: _normalizeClass([[_ctx.size, _ctx.customClass], "radio-group-content"])
      }, [
        (button.tooltipContent)
          ? (_openBlock(), _createBlock(_component_Tooltip, {
              key: 0,
              content: button.tooltipContent
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_RadioButton, {
                  modelValue: _ctx.val,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
                  key: button.value,
                  value: button.value,
                  label: button.label,
                  id: button.id,
                  disabled: button.disabled || _ctx.disabled,
                  name: _ctx.name,
                  size: _ctx.size,
                  color: _ctx.color,
                  width: _ctx.width,
                  custom: _ctx.custom,
                  onChange: _ctx.onChange
                }, null, 8, ["modelValue", "value", "label", "id", "disabled", "name", "size", "color", "width", "custom", "onChange"]))
              ]),
              _: 2
            }, 1032, ["content"]))
          : (_openBlock(), _createBlock(_component_RadioButton, {
              modelValue: _ctx.val,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.val) = $event)),
              key: button.value,
              value: button.value,
              label: button.label,
              id: button.id,
              disabled: button.disabled || _ctx.disabled,
              name: _ctx.name,
              size: _ctx.size,
              color: _ctx.color,
              width: _ctx.width,
              custom: _ctx.custom,
              onChange: _ctx.onChange
            }, null, 8, ["modelValue", "value", "label", "id", "disabled", "name", "size", "color", "width", "custom", "onChange"]))
      ], 2))
    }), 128))
  ]))
}