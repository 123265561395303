/* eslint-disable @typescript-eslint/no-explicit-any */
import { CompanyApi, CustomTargetApi, TroApi, TvDataSearchApi } from '@/api';
import {
  AreaInfoIdEnum,
  CustomTarget,
  Products,
  TroProgramBroadcast,
  TroProgramBroadcastTroProgramTypeEnum,
  TroProgramImportDetailFormAreaCodeEnum,
  TroProgramImportDetailFormTroCombinationEnum,
  TroProgramImportDetailFormTroProgramTypeEnum,
  TroProgramImportDetailList,
  TroProgramImportDetailTroPlacementStatusEnum,
  TroProjectBasicInfo,
  TroProjectBasicInfoBroadcastTimeTypeEnum,
  TroProjectBasicInfoSearchStatusEnum,
  TroProjectBasicInfoTroCombinationEnum,
  TroProjectBasicInfoTroProgramTypeEnum,
  TroProjectCmCombinationCmBuyingKindEnum,
  TroProjectCmCombinationForm,
  TroProjectDetail,
  TroProjectForm,
  TroProjectProgramSetting,
  TroProjectProgramSettingTroPlacementStatusEnum,
  TroProjectStationSetting,
  TroTargetSettings,
  TroTargetSettingsTargets,
  TvDataSearchCmCreative
} from '@/api/openapi';
import { TARGET_NAME, httpCode } from '@/common/constant';
import { getAvailableDate } from '@/common/dates';
import { DATE_FORMAT, DATE_FORMAT_SLASH } from '@/common/format';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { getBroadcastIds } from '@/composables/planning/tro/broadcastListModal';
import {
  ConditionsType,
  ExTroProgramImportDetail,
  ImportDataFormType,
  createCsvBody,
  createCsvHeader,
  getIsBroadcastError,
  setBroadcastIds,
  setIsBroadcastListError
} from '@/composables/planning/tro/importDataTable';
import { isDisabled } from '@/composables/validation';
import { PLANNING_ROUTES, ROUTE_NAMES } from '@/router';
import { useAreaOptions } from '@/store/areaOptions';
import {
  addDays,
  addWeeks,
  addYears,
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from 'date-fns';
import { storeToRefs } from 'pinia';
import { Ref, computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export type programTypes = TroProgramImportDetailFormTroProgramTypeEnum;
export type combination = TroProgramImportDetailFormTroCombinationEnum;
export type cmBuyingKind = TroProjectCmCombinationCmBuyingKindEnum;
type CsvDownloadState = 'ON' | 'OFF';
export interface InputFormType {
  programTypesValue: Ref<programTypes>;
  lastProgramType: Ref<programTypes | undefined>;
  combinationValue: Ref<combination>;
  projectId: Ref<number | undefined>;
  projectName: Ref<string>;
  searchStatus: Ref<TroProjectBasicInfoSearchStatusEnum | undefined>;
  isOpenimportDataForm: Ref<boolean>;
  importDataForm: ImportDataFormType;
  isProgramImportDetailUpdate: Ref<boolean>;
  targetName: Ref<string>;
  isCustomTarget: Ref<boolean>;
  basicTargetIds: Ref<string[] | undefined>;
  customTargetId: Ref<string | undefined>;
  initialTargetId: Ref<string[] | number[] | undefined>;
  numOfEffectiveContacts: Ref<number>;
  searchStartDate: Ref<string>;
  searchEndDate: Ref<string>;
  isOpenAnalysis: Ref<boolean>;
  cmCombinationDuration: Ref<DateRange>;
  cmBuyingKind: Ref<cmBuyingKind>;
  products: Ref<Products[]>;
  csvDownloadState: Ref<CsvDownloadState>;
  isCmCreativeOpen: Ref<boolean>;
  selectedCmCreativeIds: Ref<number[]>;
  notIncludedListIds: Ref<number[]>;
  authorSaasAccountId: Ref<number>;
  updatedAt: Ref<string>;
  importCsvData: Ref<Array<Array<string>>>;
}
interface UseValidatorReturnType {
  validProjectName: Ref<string | undefined>;
  validDuration: Ref<string | undefined>;
  validCmCombinationDuration: Ref<string | undefined>;
  validTarget: Ref<string | undefined>;
  validCmCreative: Ref<string | undefined>;
  isDisabledSubmit: Ref<boolean>;
}
interface programTypesOption {
  label: string;
  id: programTypes;
}
interface combinationOption {
  label: string;
  id: combination;
}
export enum EditType {
  create,
  edit,
  clone
}
export enum SubmitType {
  save,
  done
}
export const PROGRAM_TYPES: Array<programTypesOption> = [
  {
    label: 'レギュラー',
    id: TroProgramImportDetailFormTroProgramTypeEnum.Regular
  },
  { label: '単発', id: TroProgramImportDetailFormTroProgramTypeEnum.Single }
];
export const COMBINATION_TYPES: Array<combinationOption> = [
  { label: '予算', id: TroProgramImportDetailFormTroCombinationEnum.Budget },
  { label: '番組数', id: TroProgramImportDetailFormTroCombinationEnum.Programs }
];

// 入力フォームデータ
export const useInputFormData = (): any => {
  const inputform: InputFormType = {
    programTypesValue: ref<programTypes>(
      TroProgramImportDetailFormTroProgramTypeEnum.Regular
    ),
    lastProgramType: ref(),
    combinationValue: ref<combination>(
      TroProgramImportDetailFormTroCombinationEnum.Budget
    ),
    projectId: ref<number | undefined>(),
    projectName: ref<string>(''),
    searchStatus: ref<TroProjectBasicInfoSearchStatusEnum | undefined>(),
    isOpenimportDataForm: ref(false),
    importDataForm: {
      programImportDetail: ref(),
      broadcastTimeType: ref(
        TroProjectBasicInfoBroadcastTimeTypeEnum.ImportData
      ),
      excludesSingleBroadcast: ref(true),
      totalBudget: ref(),
      numberOfPrograms: ref(),
      stationSettingList: ref(),
      numberOfReplaceSponsoredPrograms: ref(),
      conditions: ref<ConditionsType>(ConditionsType.None),
      isError: ref(false)
    },
    isProgramImportDetailUpdate: ref(false),
    targetName: ref('個人全体'),
    isCustomTarget: ref(false),
    basicTargetIds: ref(),
    customTargetId: ref(),
    initialTargetId: ref(),
    numOfEffectiveContacts: ref(1),
    searchStartDate: ref(''),
    searchEndDate: ref(''),
    isOpenAnalysis: ref(false),
    cmCombinationDuration: ref({ start: new Date(), end: new Date() }),
    cmBuyingKind: ref(TroProjectCmCombinationCmBuyingKindEnum.All),
    products: ref([]),
    csvDownloadState: ref('OFF'),
    isCmCreativeOpen: ref(false),
    selectedCmCreativeIds: ref([]),
    notIncludedListIds: ref([]),
    authorSaasAccountId: ref(0),
    updatedAt: ref(''),
    importCsvData: ref([])
  };
  return { inputform };
};

// バリデーション
export const useValidator = (
  inputform: InputFormType,
  areaValue: Ref<AreaInfoIdEnum | undefined>,
  areaOptions: ReturnType<typeof useAreaOptions>,
  regularSearchDuraution: Ref<DateRange>,
  validCmCombinationProducts: Ref<string | undefined>,
  customTargetList: Ref<
    {
      id: number;
      label: string;
      isEnabled: boolean | undefined;
    }[]
  >,
  searcDateSelectErr: Ref<string | undefined>,
  searcDateCsvErr: Ref<string | undefined>
): UseValidatorReturnType => {
  const validProjectName = computed(() => {
    const value = inputform.projectName.value;
    if (value.length > 64) {
      return VALIDATION_MESSAGE.max64;
    }
    if (value.length && !value.match(/\S/g)) {
      return VALIDATION_MESSAGE.emptyFormat;
    }
    return undefined;
  });
  const checkDuration = (duration: DateRange | undefined) => {
    if (duration?.start && duration?.end) {
      if (differenceInDays(duration.end, duration.start) >= 371) {
        return '期間は371日間以下で選択してください';
      }
    }
    const areaInfo = areaOptions.option(areaValue.value);
    const availableDate = getAvailableDate(areaValue.value);
    if (!duration?.start || !duration?.end) {
      return undefined;
    }
    // 選べない期間を選択されている場合
    if (duration.start < availableDate || duration.end < availableDate) {
      return (
        areaInfo?.name +
        'は' +
        availableDate.getFullYear() +
        '年' +
        (availableDate.getMonth() + 1) +
        '月' +
        availableDate.getDate() +
        '日以降を指定してください。'
      );
    }
    return undefined;
  };
  const validDuration = computed(() => {
    return checkDuration(regularSearchDuraution.value);
  });
  const validCmCombinationDuration = computed(() => {
    const programTypesValue = inputform.programTypesValue.value;
    const duration = inputform.cmCombinationDuration.value;
    if (
      programTypesValue == TroProgramImportDetailFormTroProgramTypeEnum.Regular
    ) {
      const str = checkDuration(duration);
      return str;
    } else {
      return undefined;
    }
  });
  const validTarget = computed(() => {
    const targetName = inputform.targetName.value;
    const customTargetId = inputform.customTargetId.value;
    const basicTargetIds = inputform.basicTargetIds.value;
    if (targetName === TARGET_NAME.individual) {
      return undefined;
    }
    if (targetName === TARGET_NAME.customTarget) {
      if (customTargetId === undefined) {
        return 'いずれか一つは選択してください。';
      } else {
        const target = customTargetList.value.find(
          v => v.id.toString() == customTargetId
        );
        return !target?.isEnabled
          ? '別のターゲットを指定するか、カスタムターゲットを修正してください。'
          : undefined;
      }
    }
    return basicTargetIds?.length === 0
      ? 'いずれか一つは選択してください。'
      : undefined;
  });
  const validProducts = computed(() => {
    return inputform.isOpenAnalysis.value &&
      inputform.products.value.length === 0
      ? '商品／ブランドを選択してください'
      : undefined;
  });
  const validCmCreative = computed(() => {
    return inputform.isOpenAnalysis.value &&
      inputform.isCmCreativeOpen.value &&
      inputform.products.value.length === 0
      ? '商品／ブランドを選択してください'
      : undefined;
  });

  const disabled = isDisabled([
    validProjectName,
    validDuration,
    validCmCombinationDuration,
    validTarget,
    validProducts,
    validCmCreative,
    validCmCombinationProducts,
    searcDateSelectErr,
    searcDateCsvErr
  ]);
  const isDisabledSubmit = computed(() => {
    return (
      disabled.value ||
      inputform.importDataForm.isError.value ||
      inputform.projectName.value === ''
    );
  });

  return {
    validProjectName,
    validDuration,
    validCmCombinationDuration,
    validTarget,
    validCmCreative,
    isDisabledSubmit
  };
};

// 初期処理
export const created = async (
  areaValue: Ref<AreaInfoIdEnum | undefined>,
  areaOptions: ReturnType<typeof useAreaOptions>,
  route: ReturnType<typeof useRoute>,
  onChangeArea: () => Promise<void>,
  companyId: Ref<number>,
  customTargets: Ref<CustomTarget[]>,
  setInitSearchDate: () => void,
  editType: EditType,
  projectDetailData: TroProjectDetail | undefined,
  convertToInputFormData: (data: TroProjectDetail | undefined) => void,
  setImportCsvData: () => void,
  isOpenimportDataForm: Ref<boolean>
): Promise<void> => {
  try {
    // エリア取得
    areaValue.value = await areaOptions.fetch(
      route,
      undefined,
      undefined,
      true
    );
    if (projectDetailData?.basicInfo.area)
      areaValue.value = projectDetailData.basicInfo.area as AreaInfoIdEnum;
    await onChangeArea();
    if (editType !== EditType.create) {
      // 編集データの当て込み
      convertToInputFormData(projectDetailData);
      setImportCsvData();

      // インポートフォームのdisable化
      isOpenimportDataForm.value = true;
    }
    // カスタムターゲット
    const getCustomTargets = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(
      companyId.value
    );
    customTargets.value = getCustomTargets.data.list;
    // 集計期間
    setInitSearchDate();
  } catch (e) {
    if (e.state !== httpCode.forbidden) {
      toast({ title: '失敗', message: e.message, variant: 'error' });
    }
  }
};

// 番組タイプ
export const useProgramTypes = (): any => {
  const programTypes = PROGRAM_TYPES.map(v => ({
    label: v.label,
    value: v.id
  }));
  return { programTypes };
};

// 組み合わせ条件
export const useCombination = (): any => {
  const combination = COMBINATION_TYPES.map(v => ({
    label: v.label,
    value: v.id
  }));
  return { combination };
};

// エリア
export const useArea = (
  areaOptionStore: ReturnType<typeof useAreaOptions>,
  areaValue: Ref<AreaInfoIdEnum>,
  companyId: Ref<number>
): any => {
  const { selectOptions: areaSelectOptions } = storeToRefs(areaOptionStore);
  const tvStations = ref<{ code: string; label: string }[]>([]);
  const onChangeArea = async () => {
    // 放送局取得
    const res = await TvDataSearchApi.getTvdataSearchStations(
      areaValue.value,
      companyId.value,
      true
    );
    tvStations.value = [...res.data].map(v => ({
      code: v.stationCodes[0],
      label: v.displayStationName
    }));
  };
  return {
    areaSelectOptions,
    onChangeArea,
    tvStations
  };
};

const timeFormat = (time: string) => {
  const arr = time.split(':');
  return arr[0] + ':' + arr[1];
};

// CSVインポート
export const useCsvImport = (
  companyId: Ref<number>,
  inputform: InputFormType,
  tvStations: Ref<{ code: string; label: string }[]>,
  areaValue: Ref<AreaInfoIdEnum | undefined>,
  setSearcDateData: () => void,
  setCmCombinationDuration: () => void,
  regularCalendarSubId: Ref<number>,
  cmCombinationCalendarId: Ref<number>,
  customCalendarId: Ref<number>,
  setSearchDateBeforeFetch: () => void,
  router: ReturnType<typeof useRouter>
): any => {
  const importCsvFileName: Ref<string> = ref('');
  const importCsvErrorMsg: Ref<string> = ref('');
  const convertAreaCode = (
    code: AreaInfoIdEnum | undefined
  ): TroProgramImportDetailFormAreaCodeEnum => {
    switch (code) {
      case AreaInfoIdEnum.Kanto:
        return TroProgramImportDetailFormAreaCodeEnum.Kanto;
      case AreaInfoIdEnum.Chukyo:
        return TroProgramImportDetailFormAreaCodeEnum.Chukyo;
      case AreaInfoIdEnum.Kansai:
        return TroProgramImportDetailFormAreaCodeEnum.Kansai;
      default:
        return TroProgramImportDetailFormAreaCodeEnum.Kanto;
    }
  };

  const setCombinationData = () => {
    const sponsoredList = inputform.importDataForm.programImportDetail.value?.list.filter(
      v =>
        !v.troPlacementStatus ||
        v.troPlacementStatus ===
          TroProgramImportDetailTroPlacementStatusEnum.Sponsored
    );
    if (
      inputform.combinationValue.value ===
      TroProgramImportDetailFormTroCombinationEnum.Budget
    ) {
      inputform.importDataForm.totalBudget.value =
        sponsoredList?.length == 0
          ? 0
          : sponsoredList
              ?.map(v => {
                return v.exclude ? 0 : v.monthlyCost;
              })
              .reduce((a: number | undefined, b: number | undefined) => {
                return (a ?? 0) + (b ?? 0);
              });
    } else {
      const sum = sponsoredList?.length ?? 0;
      const excludeCount = sponsoredList?.filter(v => v.exclude).length ?? 0;
      const max = sum - excludeCount;
      inputform.importDataForm.numberOfPrograms.value = max == 0 ? 1 : max;
    }
  };

  const _fetchProgramImportDetails = async () => {
    if (inputform.importCsvData.value.length == 0) return;

    if (!inputform.isOpenimportDataForm.value) {
      setSearchDateBeforeFetch();
    }

    const importDetailForm = {
      troProgramType: inputform.programTypesValue.value,
      troCombination: inputform.combinationValue.value,
      importData: inputform.importCsvData.value
    };
    if (companyId.value) {
      try {
        const res = await TroApi.postCmPlanningCompaniesCompanyIdTroProjectsProgramImportDetails(
          companyId.value,
          {
            areaCode: convertAreaCode(areaValue.value),
            troProgramType: inputform.programTypesValue.value,
            troCombination: inputform.combinationValue.value,
            startDate: inputform.searchStartDate.value,
            endDate: inputform.searchEndDate.value,
            importData: importDetailForm.importData
          }
        );
        inputform.lastProgramType.value = inputform.programTypesValue.value;
        setImportData(res.data);
        if (inputform.isProgramImportDetailUpdate.value) {
          inputform.importDataForm.stationSettingList.value = tvStations.value.map(
            v => ({
              ...v,
              value: 0,
              valueItems: []
            })
          );
          inputform.importDataForm.conditions.value = ConditionsType.None;
          setCombinationData();
        }
        inputform.isOpenimportDataForm.value = true;
        setSearcDateData();
        setCmCombinationDuration();
        regularCalendarSubId.value = regularCalendarSubId.value + 1;
        cmCombinationCalendarId.value = cmCombinationCalendarId.value + 1;
        customCalendarId.value = customCalendarId.value + 1;
      } catch (e) {
        if (e.status === httpCode.unprocessableEntity && e.messages) {
          importCsvErrorMsg.value = '\n' + e.messages.join('\n');
        } else if (e.status === httpCode.forbidden) {
          router.push({
            name: ROUTE_NAMES.error,
            params: {
              name: '権限がありません。',
              message:
                'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
              back: undefined,
              status: '403 forbidden'
            },
            force: true
          });
        }
        toast({
          title: '失敗',
          message: 'CSVインポートでエラーが発生しました',
          variant: 'error'
        });
      }
    }
  };
  const [
    isFetchingProgramImportDetails,
    fetchProgramImportDetails
  ] = useLoading(_fetchProgramImportDetails);

  const isResetImportCsv: Ref<boolean> = ref(false);

  // リセット前のデータを一時的に保持する変数群
  let oldProgramType: programTypes =
    TroProgramImportDetailFormTroProgramTypeEnum.Regular;
  let oldCombination: combination =
    TroProgramImportDetailFormTroCombinationEnum.Budget;
  let oldArea: AreaInfoIdEnum | undefined = undefined;
  let oldImportCsvFileName = '';
  let oldImportCsvErrorMsg = '';
  let oldImportCsvData: Array<Array<string>> = [];

  const resetImportCsv = () => {
    oldProgramType = inputform.programTypesValue.value;
    oldCombination = inputform.combinationValue.value;
    oldArea = areaValue.value;
    oldImportCsvFileName = importCsvFileName.value;
    oldImportCsvErrorMsg = importCsvErrorMsg.value;
    oldImportCsvData = inputform.importCsvData.value;
    importCsvFileName.value = '';
    importCsvErrorMsg.value = '';
    inputform.importCsvData.value = [];
    isResetImportCsv.value = true;
  };

  const cancelResetImportCsv = () => {
    inputform.programTypesValue.value = oldProgramType;
    inputform.combinationValue.value = oldCombination;
    areaValue.value = oldArea;
    importCsvFileName.value = oldImportCsvFileName;
    importCsvErrorMsg.value = oldImportCsvErrorMsg;
    inputform.importCsvData.value = oldImportCsvData;
    isResetImportCsv.value = false;
  };

  const getIds = (
    broadcastList: TroProgramBroadcast[] | undefined,
    importDataStartTime: string,
    importDataEndTime: string,
    isExcluded: boolean,
    isBroadcastTime: boolean
  ): number[] => {
    if (inputform.importDataForm.excludesSingleBroadcast.value) {
      // 単発回を対象外 true
      if (isBroadcastTime) {
        return getBroadcastIds(
          broadcastList,
          importDataStartTime,
          importDataEndTime,
          isExcluded
        );
      } else {
        return isExcluded
          ? broadcastList
              ?.filter(
                broadcast =>
                  broadcast.troProgramType ===
                  TroProgramBroadcastTroProgramTypeEnum.Single
              )
              .map(broadcast => broadcast.id) ?? []
          : broadcastList
              ?.filter(
                broadcast =>
                  broadcast.troProgramType !==
                  TroProgramBroadcastTroProgramTypeEnum.Single
              )
              .map(broadcast => broadcast.id) ?? [];
      }
    } else {
      // 単発回を対象外 false
      return isExcluded
        ? []
        : broadcastList?.map(broadcast => broadcast.id) ?? [];
    }
  };

  const setImportData = (addData: TroProgramImportDetailList) => {
    const today = new Date();
    const isBroadcastTime =
      inputform.importDataForm.broadcastTimeType.value ==
      TroProjectBasicInfoBroadcastTimeTypeEnum.BroadcastTimes;
    if (!inputform.importDataForm.programImportDetail.value) {
      let list: ExTroProgramImportDetail[] = addData.list.map(v => ({
        ...v,
        broadcastListCount: v.broadcastList?.length,
        formatedBroadcastDate: new Date(v.broadcastDate ?? today),
        broadcastList: v.broadcastList?.map(broadcast => ({
          ...broadcast
        })),
        broadcasts: [],
        excludedBroadcasts: [],
        isBroadcastListError: false
      }));
      if (
        inputform.programTypesValue.value ==
        TroProgramImportDetailFormTroProgramTypeEnum.Regular
      ) {
        list = setBroadcastIds(
          inputform.importDataForm.excludesSingleBroadcast.value,
          isBroadcastTime,
          list
        );
        list = setIsBroadcastListError(list);
      }
      inputform.importDataForm.programImportDetail.value = {
        totalCount: list.length,
        list: list
      };
      inputform.isProgramImportDetailUpdate.value = true;
      return;
    }
    // 既存のテーブルデータが存在場合
    const baseList = inputform.importDataForm.programImportDetail.value?.list;
    const addList = addData.list;
    let newList: ExTroProgramImportDetail[] = [];
    let duplicaionCount = 0;
    addList.map(add => {
      const duplicaion = baseList.find(
        base =>
          base.stationCode == add.stationCode &&
          base.programName == add.programName &&
          timeFormat(base.startTime) == timeFormat(add.startTime) &&
          timeFormat(base.endTime) == timeFormat(add.endTime) &&
          (!base.monthlyCost || base.monthlyCost == add.monthlyCost) &&
          (!base.troPlacementStatus ||
            base.troPlacementStatus == add.troPlacementStatus) &&
          (!base.dayOfWeek || base.dayOfWeek == add.dayOfWeek) &&
          (!base.broadcastDate || base.broadcastDate == add.broadcastDate) &&
          (!base.analysisDate || base.analysisDate == add.analysisDate)
      );
      if (duplicaion) {
        // 重複列の場合
        duplicaionCount = duplicaionCount + 1;
        const baseBroadcasts = duplicaion.broadcasts;
        const baseExcludedBroadcasts = duplicaion.excludedBroadcasts;
        const newBroadcasts: number[] = [];
        const newExcludedBroadcasts: number[] = [];
        add.broadcastList?.map(addBroadcast => {
          if (baseBroadcasts.includes(addBroadcast.id)) {
            newBroadcasts.push(addBroadcast.id);
          } else if (baseExcludedBroadcasts.includes(addBroadcast.id)) {
            newExcludedBroadcasts.push(addBroadcast.id);
          } else if (isBroadcastTime) {
            // 新規追加の放送回で放送時間を固定がtrueになっている場合
            const ProgramTypeList = add.broadcastList
              ?.filter(v => v.broadcastDate == addBroadcast.broadcastDate)
              .map(v => v.troProgramType);
            if (
              ProgramTypeList?.includes(
                TroProgramBroadcastTroProgramTypeEnum.Single
              )
            ) {
              newExcludedBroadcasts.push(addBroadcast.id);
            } else {
              newBroadcasts.push(addBroadcast.id);
            }
          } else {
            if (
              addBroadcast.troProgramType ==
              TroProgramBroadcastTroProgramTypeEnum.Single
            ) {
              newExcludedBroadcasts.push(addBroadcast.id);
            } else {
              newBroadcasts.push(addBroadcast.id);
            }
          }
        });
        duplicaion.broadcastList = add.broadcastList;
        duplicaion.broadcastListCount = add.broadcastList?.length;
        duplicaion.broadcasts = newBroadcasts;
        duplicaion.excludedBroadcasts = newExcludedBroadcasts;
        duplicaion.monthlyCost = duplicaion.monthlyCost ?? add.monthlyCost;
        duplicaion.troPlacementStatus =
          duplicaion.troPlacementStatus ?? add.troPlacementStatus;
        duplicaion.dayOfWeek = duplicaion.dayOfWeek ?? add.dayOfWeek;
        duplicaion.broadcastDate =
          duplicaion.broadcastDate ?? add.broadcastDate;
        duplicaion.analysisDate = duplicaion.analysisDate ?? add.analysisDate;
        duplicaion.isBroadcastListError = getIsBroadcastError(duplicaion);
        newList.push(duplicaion);
      } else {
        // 新規列追加の場合
        const newRow = {
          ...add,
          broadcastListCount: add.broadcastList?.length,
          formatedBroadcastDate: new Date(add.broadcastDate ?? today),
          broadcastList: add.broadcastList?.map(broadcast => ({
            ...broadcast
          })),
          broadcasts: getIds(
            add.broadcastList,
            add.startTime,
            add.endTime,
            false,
            isBroadcastTime
          ),
          excludedBroadcasts: getIds(
            add.broadcastList,
            add.startTime,
            add.endTime,
            true,
            isBroadcastTime
          ),
          isBroadcastListError: false
        };
        newList.push(newRow);
      }
    });
    newList = setIsBroadcastListError(newList);
    inputform.isProgramImportDetailUpdate.value =
      duplicaionCount !=
      inputform.importDataForm.programImportDetail.value.list.length;
    inputform.importDataForm.programImportDetail.value = {
      totalCount: newList.length,
      list: newList
    };
  };

  // 編集画面、複製画面の場合、詳細取得の値を元にCSVデータの初期値を作成する
  const setImportCsvData = () => {
    const header = createCsvHeader(
      inputform.programTypesValue.value,
      inputform.combinationValue.value
    );
    const body = createCsvBody(
      inputform.programTypesValue.value,
      inputform.combinationValue.value,
      inputform.importDataForm.programImportDetail.value?.list
    );
    inputform.importCsvData.value = [...header.concat(body)];
  };

  watch(
    importCsvErrorMsg,
    value => {
      if (value.length > 0) {
        importCsvFileName.value = '';
        inputform.importCsvData.value = [];
      }
    },
    { deep: true }
  );

  return {
    importCsvFileName,
    isFetchingProgramImportDetails,
    fetchProgramImportDetails,
    importCsvErrorMsg,
    isResetImportCsv,
    resetImportCsv,
    cancelResetImportCsv,
    setImportCsvData
  };
};

// ターゲット
export const useTargets = (inputform: InputFormType): any => {
  const customTargets = ref<CustomTarget[]>([]);
  const customTargetList = computed(() => {
    return customTargets.value.map(({ id, name, isEnabled }) => ({
      id,
      label: name,
      isEnabled
    }));
  });
  const onChangeTarget = (target: { name: string; value: any }) => {
    inputform.targetName.value = target.name;
    // 個人全体の場合
    if (inputform.targetName.value === TARGET_NAME.individual) {
      inputform.isCustomTarget.value = false;
      inputform.basicTargetIds.value = undefined;
      inputform.customTargetId.value = undefined;
      return;
    }
    // カスタムターゲットの場合
    if (inputform.targetName.value === TARGET_NAME.customTarget) {
      inputform.isCustomTarget.value = true;
      inputform.basicTargetIds.value = undefined;
      if (target.value && target.value.id)
        inputform.customTargetId.value = target.value.id;
      return;
    }
    // 基本属性ターゲットの場合
    inputform.isCustomTarget.value = false;
    inputform.customTargetId.value = undefined;
    if (target.value) inputform.basicTargetIds.value = target.value;
  };

  return {
    customTargets,
    customTargetList,
    onChangeTarget
  };
};

// 有効接触回数
export const useNumOfEffectiveContacts = (): any => {
  const numOfEffectiveContactsItems = [...Array(10)].map((_, i) => i + 1);
  return { numOfEffectiveContactsItems };
};

// 集計期間、実質値参照期間
export const useSearcDate = (
  inputform: InputFormType,
  minDate: Ref<Date>
): any => {
  const today = new Date();
  const weekDays = 7;
  type searchDateValueType = 'lastYear' | 'twoAgo' | 'oneAgo' | 'custom';
  const searchDateType = {
    lastYear: { label: '前年', value: 'lastYear' },
    twoAgo: { label: '2クール前', value: 'twoAgo' },
    oneAgo: { label: '1クール前', value: 'oneAgo' },
    custom: { label: 'カスタム', value: 'custom' }
  };
  const searchDateModel: Ref<searchDateValueType> = ref('lastYear');
  const customCalendarId = ref(0);
  const searcDateSelectErr = ref();
  const searcDateCsvErr = ref();

  const planningStartDate = ref(format(today, DATE_FORMAT));
  const planningEndDate = ref(format(today, DATE_FORMAT));
  const customPlanningStartDate = ref(format(today, DATE_FORMAT));
  const customPlanningEndDate = ref(format(today, DATE_FORMAT));

  const setPlanningData = () => {
    const list = inputform.importDataForm.programImportDetail.value?.list;
    let tmpPlanningStarDate = addYears(today, 100);
    list?.map(v => {
      const broadcastDateList = v.broadcastDate?.split(',');
      broadcastDateList?.map(broadcast => {
        const bDate = new Date(broadcast ?? today);
        tmpPlanningStarDate = isBefore(bDate, tmpPlanningStarDate)
          ? bDate
          : tmpPlanningStarDate;
      });
    });
    planningStartDate.value = format(tmpPlanningStarDate, DATE_FORMAT);

    let tmpPlanningEndDate = subYears(today, 100);
    list?.map(v => {
      const broadcastDateList = v.broadcastDate?.split(',');
      broadcastDateList?.map(broadcast => {
        const bDate = new Date(broadcast ?? today);
        tmpPlanningEndDate = isAfter(bDate, tmpPlanningEndDate)
          ? bDate
          : tmpPlanningEndDate;
      });
    });
    planningEndDate.value = format(tmpPlanningEndDate, DATE_FORMAT);

    // カスタム日付用
    let tmpCustomPlanningStartDate = addYears(today, 100);
    list?.map(v => {
      if (!v.analysisDate || v.analysisDate === '前年同日') {
        const broadcastDateList = v.broadcastDate?.split(',');
        broadcastDateList?.map(broadcast => {
          const bDate = new Date(broadcast ?? today);
          tmpCustomPlanningStartDate = isBefore(
            bDate,
            tmpCustomPlanningStartDate
          )
            ? bDate
            : tmpCustomPlanningStartDate;
        });
      }
    });
    customPlanningStartDate.value = format(
      tmpCustomPlanningStartDate,
      DATE_FORMAT
    );

    let tmpCustomPlanningEndDate = subYears(today, 100);
    list?.map(v => {
      if (!v.analysisDate || v.analysisDate === '前年同日') {
        const broadcastDateList = v.broadcastDate?.split(',');
        broadcastDateList?.map(broadcast => {
          const bDate = new Date(broadcast ?? today);
          tmpCustomPlanningEndDate = isAfter(bDate, tmpCustomPlanningEndDate)
            ? bDate
            : tmpCustomPlanningEndDate;
        });
      }
    });
    customPlanningEndDate.value = format(tmpCustomPlanningEndDate, DATE_FORMAT);
  };

  const getMinDate = (dateList: Date[]) => {
    let returnDate = addYears(today, 100);
    const size = dateList.length;
    for (let i = 0; i < size; i += 1) {
      returnDate = isBefore(dateList[i], returnDate) ? dateList[i] : returnDate;
    }
    return returnDate;
  };

  const getMaxDate = (dateList: Date[]) => {
    let returnDate = subYears(today, 100);
    const size = dateList.length;
    for (let i = 0; i < size; i += 1) {
      returnDate = isAfter(dateList[i], returnDate) ? dateList[i] : returnDate;
    }
    return returnDate;
  };

  const getSeasonStartMonth = (month: number) => {
    switch (month) {
      case 1:
      case 2:
      case 3:
        return 1;
      case 4:
      case 5:
      case 6:
        return 4;
      case 7:
      case 8:
      case 9:
        return 7;
      case 10:
      case 11:
      case 12:
      default:
        return 10;
    }
  };

  const getFistMonday = (targetDate: Date) => {
    const year = targetDate.getFullYear();
    const month = targetDate.getMonth() + 1;
    let fistMonday: Date = new Date();
    for (let i = 1; i <= weekDays; i++) {
      const tmpDate = new Date(year, month - 1, i);
      if (tmpDate.getDay() !== 1) continue;
      fistMonday = tmpDate;
      break;
    }
    return fistMonday;
  };

  const getSeasonStart = (targetDate: Date) => {
    const year = targetDate.getFullYear();
    const month = targetDate.getMonth() + 1;
    const tmpMonth = getSeasonStartMonth(month);
    let start = getFistMonday(new Date(year, tmpMonth - 1, 1));
    if (isBefore(targetDate, start)) {
      const before = subMonths(targetDate, 1);
      const beforeYear = before.getFullYear();
      const beforeMonth = before.getMonth() + 1;
      const beforeTmpMonth = getSeasonStartMonth(beforeMonth);
      start = getFistMonday(new Date(beforeYear, beforeTmpMonth - 1, 1));
    }
    return start;
  };

  const getWeekCount = (targetDate: Date) => {
    const seasonStart = getSeasonStart(targetDate);
    const diff = differenceInDays(targetDate, seasonStart);
    let tmpDate = seasonStart;
    let count = 0;
    for (let i = 0; i <= diff; i++) {
      if (tmpDate.getDay() == 1) {
        count += 1;
      }
      tmpDate = addDays(tmpDate, 1);
    }
    return count;
  };

  const getBeforeSeason = (targetDate: Date, count: number) => {
    let beforeSeasonDate = targetDate;
    for (let i = 1; i <= count; i++) {
      const w = getWeekCount(beforeSeasonDate) == 14 ? 14 : 13;
      beforeSeasonDate = subWeeks(beforeSeasonDate, w);
    }
    return beforeSeasonDate;
  };

  const getBeforeSeasonStart = (targetDate: Date, count: number) => {
    const beforeSeasonDate = getBeforeSeason(targetDate, count);
    return getSeasonStart(beforeSeasonDate);
  };
  const getBeforeSeasonEnd = (targetDate: Date, count: number) => {
    const beforeSeasonDate = getBeforeSeason(targetDate, count - 1);
    const start = getSeasonStart(beforeSeasonDate);
    return subDays(start, 1);
  };

  const getLastYearStart = (targetDate: Date) => {
    const year = targetDate.getFullYear();
    let yearStart = getFistMonday(new Date(year, 0, 1));
    if (isBefore(targetDate, yearStart)) {
      const before = subMonths(targetDate, 1);
      const beforeYear = before.getFullYear();
      yearStart = getFistMonday(new Date(beforeYear, 0, 1));
    }
    // 対象日は年の何周目か
    const diff = differenceInDays(targetDate, yearStart);
    let tmpDate = yearStart;
    let weekCount = 0;
    for (let i = 0; i <= diff; i++) {
      if (tmpDate.getDay() == 1) {
        weekCount += 1;
      }
      tmpDate = addDays(tmpDate, 1);
    }
    const lastYear = subYears(yearStart, 1).getFullYear();
    const lastYearStart = getFistMonday(new Date(lastYear, 0, 1));
    // 前年は何週あるか
    const diffLastYear = differenceInDays(yearStart, lastYearStart);
    let tmpDateLastYear = lastYearStart;
    let maxWeekCountLastYear = 0;
    for (let i = 0; i <= diffLastYear; i++) {
      if (tmpDateLastYear.getDay() == 1) {
        maxWeekCountLastYear += 1;
      }
      tmpDateLastYear = addDays(tmpDateLastYear, 1);
    }
    // 対象日の何周目の値が前年の最大週を超えた場合
    if (weekCount > maxWeekCountLastYear) {
      weekCount = weekCount - 1;
    }
    // 対象日の曜日が月曜から何日目か
    const dayOfWeekCount =
      targetDate.getDay() - 1 >= 0 ? targetDate.getDay() - 1 : 6;

    let lastYearDate = addWeeks(lastYearStart, weekCount - 1);
    lastYearDate = addDays(lastYearDate, dayOfWeekCount);
    return lastYearDate;
  };

  const regularSearchDuraution: Ref<DateRange> = ref({
    start: getBeforeSeasonStart(today, 1),
    end: getBeforeSeasonEnd(today, 1)
  });
  const regularCalendarSubId = ref(0);

  // データが存在する期間
  const dateWithData = ref({ start: today, end: today });
  const setDateWithData = () => {
    const today = new Date();
    const searchHour = today.getHours();
    const before = searchHour < 5 ? 4 : 3;
    dateWithData.value = { start: minDate.value, end: subDays(today, before) };
  };
  // データが存在する期間内か判定
  const checkWithinDateWithData = (start: Date, end: Date): boolean => {
    if (isAfter(start, dateWithData.value.start)) {
      if (
        start == dateWithData.value.end ||
        isBefore(start, dateWithData.value.end)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (start == dateWithData.value.start) {
      return true;
    } else {
      if (
        end == dateWithData.value.start ||
        isAfter(end, dateWithData.value.start)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const searchDateList: Ref<{
    lastYearStart: Date | undefined;
    lastYearEnd: Date | undefined;
    oneAgoStart: Date | undefined;
    oneAgoEnd: Date | undefined;
    twoAgoStart: Date | undefined;
    twoAgoEnd: Date | undefined;
    lastYearList: Date[];
    evenDateList: Date[];
    analysisDateList: Date[];
  }> = ref({
    lastYearStart: undefined,
    lastYearEnd: undefined,
    oneAgoStart: undefined,
    oneAgoEnd: undefined,
    twoAgoStart: undefined,
    twoAgoEnd: undefined,
    lastYearList: [],
    evenDateList: [],
    analysisDateList: []
  });
  const setSearchDateList = (
    csvList?: {
      broadcastDate: string;
      analysisDate: string;
    }[]
  ) => {
    const analysisDateList: Date[] = [];
    const evenDateList: Date[] = [];
    const lastYearList: Date[] = [];
    const oneAgoList: Date[] = [];
    const twoAgoList: Date[] = [];
    const setDate = (analysisDate: string, date: Date) => {
      const isDate = !isNaN(new Date(analysisDate).getTime());
      if (isDate) {
        analysisDateList.push(new Date(analysisDate));
      } else if (analysisDate === '前年同日') {
        const lastYearDate =
          format(date, 'MM/dd') == '02/29' ? undefined : subYears(date, 1);
        if (lastYearDate) {
          evenDateList.push(lastYearDate);
        }
      } else {
        //null、省略の場合
        lastYearList.push(getLastYearStart(date));
        oneAgoList.push(getBeforeSeason(date, 1));
        twoAgoList.push(getBeforeSeason(date, 2));
      }
    };
    if (csvList) {
      csvList.map(v => {
        const bDate = new Date(v.broadcastDate ?? today);
        setDate(v.analysisDate ?? '', bDate);
      });
    } else {
      const list = inputform.importDataForm.programImportDetail.value?.list;
      list?.map(v => {
        const broadcastDateList = v.broadcastDate?.split(',');
        broadcastDateList?.map(broadcast => {
          const bDate = new Date(broadcast ?? today);
          setDate(v.analysisDate ?? '', bDate);
        });
      });
    }
    const yearList = lastYearList;
    evenDateList.map(v => {
      yearList.push(v);
    });
    const getStart = (list: Date[]) => {
      if (list.length > 0) {
        return getMinDate(list);
      } else if (analysisDateList.length > 0) {
        return getMinDate(analysisDateList);
      }
    };
    const getEnd = (list: Date[]) => {
      if (list.length > 0) {
        return getMaxDate(list);
      } else if (analysisDateList.length > 0) {
        return getMaxDate(analysisDateList);
      }
    };
    searchDateList.value = {
      lastYearStart: getStart(yearList),
      lastYearEnd: getEnd(yearList),
      oneAgoStart: getStart(oneAgoList),
      oneAgoEnd: getEnd(oneAgoList),
      twoAgoStart: getStart(twoAgoList),
      twoAgoEnd: getEnd(twoAgoList),
      lastYearList: lastYearList,
      evenDateList: evenDateList,
      analysisDateList: analysisDateList
    };
  };

  const displaySearchDateList: Ref<{
    data: {
      lastYear: string | undefined;
      oneAgo: string | undefined;
      twoAgo: string | undefined;
    };
    disabled: {
      lastYear: boolean;
      oneAgo: boolean;
      twoAgo: boolean;
      custom: boolean;
    };
  }> = ref({
    data: {
      lastYear: undefined,
      oneAgo: undefined,
      twoAgo: undefined
    },
    disabled: {
      lastYear: false,
      oneAgo: false,
      twoAgo: false,
      custom: false
    }
  });
  const seDisplaySearchDateList = () => {
    const lastYearStart = searchDateList.value.lastYearStart;
    const lastYearEnd = searchDateList.value.lastYearEnd;
    const oneAgoStart = searchDateList.value.oneAgoStart;
    const oneAgoEnd = searchDateList.value.oneAgoEnd;
    const twoAgoStart = searchDateList.value.twoAgoStart;
    const twoAgoEnd = searchDateList.value.twoAgoEnd;
    const lastYearList = searchDateList.value.lastYearList;
    const evenDateList = searchDateList.value.evenDateList;
    const analysisDateList = searchDateList.value.analysisDateList;
    const lastYear = () => {
      if (lastYearStart && lastYearEnd) {
        return (
          format(lastYearStart, DATE_FORMAT_SLASH) +
          '-' +
          format(lastYearEnd, DATE_FORMAT_SLASH)
        );
      }
    };
    const oneAgo = () => {
      if (oneAgoStart && oneAgoEnd) {
        return (
          format(oneAgoStart, DATE_FORMAT_SLASH) +
          '-' +
          format(oneAgoEnd, DATE_FORMAT_SLASH)
        );
      }
    };
    const twoAgo = () => {
      if (twoAgoStart && twoAgoEnd) {
        return (
          format(twoAgoStart, DATE_FORMAT_SLASH) +
          '-' +
          format(twoAgoEnd, DATE_FORMAT_SLASH)
        );
      }
    };
    // 画面表示用
    const dateList = {
      lastYear: lastYear(),
      oneAgo: oneAgo(),
      twoAgo: twoAgo()
    };

    let lastYearDisabled = false;
    let oneAgoDisabled = false;
    let twoAgoDisabled = false;
    let customDisabled = false;
    if (evenDateList.length > 0) {
      oneAgoDisabled = true;
      twoAgoDisabled = true;
      customDisabled = true;
    }
    if (
      lastYearList.length == 0 &&
      evenDateList.length == 0 &&
      analysisDateList.length > 0
    ) {
      lastYearDisabled = true;
      oneAgoDisabled = true;
      twoAgoDisabled = true;
      customDisabled = true;
    }

    if (lastYearStart && lastYearEnd) {
      const check = !checkWithinDateWithData(lastYearStart, lastYearEnd);
      lastYearDisabled = check ? check : lastYearDisabled;
    } else {
      lastYearDisabled = true;
    }
    if (oneAgoStart && oneAgoEnd) {
      const check = !checkWithinDateWithData(oneAgoStart, oneAgoEnd);
      oneAgoDisabled = check ? check : oneAgoDisabled;
    } else {
      oneAgoDisabled = true;
    }
    if (twoAgoStart && twoAgoEnd) {
      const check = !checkWithinDateWithData(twoAgoStart, twoAgoEnd);
      twoAgoDisabled = check ? check : twoAgoDisabled;
    } else {
      twoAgoDisabled = true;
    }

    // Disabled用
    const disabledList = {
      lastYear: lastYearDisabled,
      oneAgo: oneAgoDisabled,
      twoAgo: twoAgoDisabled,
      custom: customDisabled
    };

    if (!inputform.isOpenimportDataForm.value) {
      if (lastYearDisabled) {
        customDisabled
          ? (searchDateModel.value = 'lastYear')
          : (searchDateModel.value = 'custom');
      } else {
        searchDateModel.value = 'lastYear';
      }
    }

    displaySearchDateList.value = { data: dateList, disabled: disabledList };
  };

  // カスタム日付関連
  const getCustomEndDate = (date: Date) => {
    const planningEndDayOfWeek = new Date(customPlanningEndDate.value).getDay();
    let endDate = date;
    for (let i = 0; i <= weekDays; i++) {
      const tmpDate = subDays(date, i);
      if (tmpDate.getDay() === planningEndDayOfWeek) {
        endDate = tmpDate;
        break;
      }
    }
    return endDate;
  };

  const initCustomEndDate = ref(new Date());
  const initCustomStartDate = ref(new Date()); // カスタム日付初期値
  const customMax = ref(new Date());
  const setCustomDateValues = () => {
    initCustomEndDate.value = getCustomEndDate(dateWithData.value.end);
    const pStart = new Date(customPlanningStartDate.value);
    const pEnd = new Date(customPlanningEndDate.value);
    const diff = differenceInDays(pEnd, pStart);
    const initEnd = initCustomEndDate.value;
    initCustomStartDate.value = subDays(initEnd, diff);
    let max = initCustomStartDate.value;
    for (let i = 0; i <= weekDays; i++) {
      const tmpDate = addDays(max, i);
      if (tmpDate.getDay() === 0) {
        max = tmpDate;
        break;
      }
    }
    customMax.value = max;
  };

  // 月曜を0としたい場合に使用（月:0 〜 日:6にする）
  const getDayCustom = (targetDate: Date) => {
    const day = targetDate.getDay() - 1;
    return day == -1 ? 6 : day;
  };

  const customDurationStart = ref<Date>(new Date());
  const customDuration = computed(() => {
    const pStart = new Date(customPlanningStartDate.value);
    const pEnd = new Date(customPlanningEndDate.value);
    const selectDay = getDayCustom(customDurationStart.value);
    const pStartDay = getDayCustom(pStart);
    let customStart = customDurationStart.value;
    if (selectDay < pStartDay) {
      for (let i = 0; i <= weekDays; i++) {
        const tmpDate = addDays(customStart, i);
        if (getDayCustom(tmpDate) === pStartDay) {
          customStart = tmpDate;
          break;
        }
      }
    } else {
      for (let i = 0; i <= weekDays; i++) {
        const tmpDate = subDays(customStart, i);
        if (getDayCustom(tmpDate) === pStartDay) {
          customStart = tmpDate;
          break;
        }
      }
    }
    customDurationStart.value = customStart;
    const diff = differenceInDays(pEnd, pStart);

    const list = displaySearchDateList.value.disabled;
    if (list.custom && list.lastYear && list.oneAgo && list.twoAgo) {
      // 参照日付に、前年同日、未指定、が無い場合
      return {
        start: searchDateList.value.lastYearStart ?? today,
        end: searchDateList.value.lastYearEnd ?? today
      };
    }

    return {
      start: customDurationStart.value,
      end: addDays(customStart, diff)
    };
  });

  // 指定日が期間内か判定
  const checkWithinPeriod = (
    PeriodStr: Date,
    PeriodEnd: Date,
    day: Date
  ): boolean => {
    if (isSameDay(day, PeriodStr) || isSameDay(day, PeriodEnd)) return true;
    return isAfter(day, PeriodStr) && isBefore(day, PeriodEnd) ? true : false;
  };

  // 分析日付指定で日付指定が存在する場合、実績参照期間の各値の範囲内か判定
  const checkAnalysisInRange = () => {
    searcDateCsvErr.value = undefined;
    // 分析日付指定に同日付を含んでいるかの判定用
    const isHasSameday = searchDateList.value.evenDateList.length > 0;
    if (searchDateList.value.analysisDateList.length === 0) return;
    const analysisMaxDate = getMaxDate(searchDateList.value.analysisDateList);
    const analysisMinDate = getMinDate(searchDateList.value.analysisDateList);

    const lastYearStart = searchDateList.value.lastYearStart;
    const lastYearEnd = searchDateList.value.lastYearEnd;
    if (!lastYearStart || !lastYearEnd) return;
    const isWithinLastYear =
      checkWithinPeriod(lastYearStart, lastYearEnd, analysisMaxDate) &&
      checkWithinPeriod(lastYearStart, lastYearEnd, analysisMinDate);
    if (!isWithinLastYear) displaySearchDateList.value.disabled.lastYear = true;
    const twoAgoStart = searchDateList.value.twoAgoStart;
    const twoAgoEnd = searchDateList.value.twoAgoEnd;
    if (!twoAgoStart || !twoAgoEnd) return;
    const isWithinTwoAgo =
      checkWithinPeriod(twoAgoStart, twoAgoEnd, analysisMaxDate) &&
      checkWithinPeriod(twoAgoStart, twoAgoEnd, analysisMinDate);
    if (!isWithinTwoAgo) displaySearchDateList.value.disabled.twoAgo = true;
    const oneAgoStart = searchDateList.value.oneAgoStart;
    const oneAgoEnd = searchDateList.value.oneAgoEnd;
    if (!oneAgoStart || !oneAgoEnd) return;
    const isWithinOneAgo =
      checkWithinPeriod(oneAgoStart, oneAgoEnd, analysisMaxDate) &&
      checkWithinPeriod(oneAgoStart, oneAgoEnd, analysisMinDate);
    if (!isWithinOneAgo) displaySearchDateList.value.disabled.oneAgo = true;
    const cpStart = new Date(customPlanningStartDate.value);
    const cpEnd = new Date(customPlanningEndDate.value);
    const cRange = differenceInDays(cpEnd, cpStart);
    let cTmpStart = initCustomStartDate.value;
    let cTmpEnd = addDays(cTmpStart, cRange);
    let isWithinCustom = false;
    const limit = 500; //infinite loop回避用
    for (let i = 0; i <= limit; i++) {
      isWithinCustom =
        checkWithinPeriod(cTmpStart, cTmpEnd, analysisMaxDate) &&
        checkWithinPeriod(cTmpStart, cTmpEnd, analysisMinDate);
      if (isWithinCustom) break;
      if (isAfter(analysisMinDate, cTmpEnd)) break;
      cTmpStart = subDays(cTmpStart, 7);
      cTmpEnd = addDays(cTmpStart, cRange);
    }
    if (!isWithinCustom) {
      displaySearchDateList.value.disabled.custom = true;
    }

    if (
      (!isWithinLastYear &&
        !isWithinTwoAgo &&
        !isWithinOneAgo &&
        !isWithinCustom) ||
      (isHasSameday && !isWithinLastYear)
    ) {
      // 実績参照期間全ての範囲で日付指定が含まれていない。または、
      // 同日付あり且つ分析日付指定で日付指定ありで前年の範囲に日付指定が含まれていない
      searcDateCsvErr.value =
        '実績参照期間の指定が不適切です。番組の分析日付指定を変更して再インポートしてください。';
    }

    if (
      !inputform.isOpenimportDataForm.value ||
      inputform.isProgramImportDetailUpdate.value
    ) {
      if (isWithinCustom) {
        // カスタム日付の初期値変更
        customDurationStart.value = cTmpStart;
        initCustomStartDate.value = cTmpStart;
      }

      if (!displaySearchDateList.value.disabled.lastYear) {
        searchDateModel.value = 'lastYear';
      } else if (!displaySearchDateList.value.disabled.custom) {
        searchDateModel.value = 'custom';
      } else if (!displaySearchDateList.value.disabled.oneAgo) {
        searchDateModel.value = 'oneAgo';
      } else if (!displaySearchDateList.value.disabled.twoAgo) {
        searchDateModel.value = 'twoAgo';
      } else {
        searchDateModel.value = 'lastYear';
      }
    }
  };

  const setSearchDate = () => {
    searcDateSelectErr.value = undefined;
    if (
      inputform.programTypesValue.value ==
      TroProgramImportDetailFormTroProgramTypeEnum.Regular
    ) {
      inputform.searchStartDate.value = format(
        regularSearchDuraution.value.start ?? new Date(),
        DATE_FORMAT
      );
      inputform.searchEndDate.value = format(
        regularSearchDuraution.value.end ?? new Date(),
        DATE_FORMAT
      );
    } else {
      if (searchDateModel.value == 'lastYear') {
        const start = searchDateList.value.lastYearStart;
        const end = searchDateList.value.lastYearEnd;
        if (start && end) {
          inputform.searchStartDate.value = format(start, DATE_FORMAT);
          inputform.searchEndDate.value = format(end, DATE_FORMAT);
        }
      } else if (searchDateModel.value == 'twoAgo') {
        const start = searchDateList.value.twoAgoStart;
        const end = searchDateList.value.twoAgoEnd;
        if (start && end) {
          inputform.searchStartDate.value = format(start, DATE_FORMAT);
          inputform.searchEndDate.value = format(end, DATE_FORMAT);
        }
      } else if (searchDateModel.value == 'oneAgo') {
        const start = searchDateList.value.oneAgoStart;
        const end = searchDateList.value.oneAgoEnd;
        if (start && end) {
          inputform.searchStartDate.value = format(start, DATE_FORMAT);
          inputform.searchEndDate.value = format(end, DATE_FORMAT);
        }
      } else if (searchDateModel.value == 'custom') {
        const start = customDuration.value.start;
        const end = customDuration.value.end;
        if (searchDateList.value.analysisDateList.length > 0) {
          const analysisMaxDate = getMaxDate(
            searchDateList.value.analysisDateList
          );
          const analysisMinDate = getMinDate(
            searchDateList.value.analysisDateList
          );
          // 選択したカスタム日付の範囲内に分析日付指定の日付指定が含まれるか判定
          if (
            !checkWithinPeriod(start, end, analysisMaxDate) ||
            !checkWithinPeriod(start, end, analysisMinDate)
          ) {
            searcDateSelectErr.value =
              '実績参照期間の指定が不適切です。番組の分析日付指定を変更して再インポートするか、' +
              '実績参照期間を変更し分析日付指定が実績参照期間に含まれるようにしてください。';
          }
        }
        if (start && end) {
          inputform.searchStartDate.value = format(start, DATE_FORMAT);
          inputform.searchEndDate.value = format(end, DATE_FORMAT);
        }
      }
    }
  };

  const setInitRegularSearchDate = () => {
    inputform.searchStartDate.value = format(
      getBeforeSeasonStart(today, 1),
      DATE_FORMAT
    );
    inputform.searchEndDate.value = format(
      getBeforeSeasonEnd(today, 1),
      DATE_FORMAT
    );
  };

  const setInitSearchDate = () => {
    if (!inputform.searchStartDate.value && !inputform.searchEndDate.value) {
      setInitRegularSearchDate();
    }
  };

  const setSearcDateData = () => {
    setDateWithData();
    setPlanningData();
    setSearchDateList();
    seDisplaySearchDateList();
    setCustomDateValues();
    if (!inputform.isOpenimportDataForm.value) {
      customDurationStart.value = initCustomStartDate.value;
    }
    checkAnalysisInRange();
    setSearchDate();
  };

  const setSearchDateModel = (start: Date, end: Date) => {
    const list = displaySearchDateList.value.disabled;
    if (list.custom && list.lastYear && list.oneAgo && list.twoAgo) return;
    const today = new Date();
    checkAnalysisInRange();
    if (
      isSameDay(start, searchDateList.value.lastYearStart ?? today) &&
      isSameDay(end, searchDateList.value.lastYearEnd ?? today)
    ) {
      searchDateModel.value = 'lastYear';
      customDurationStart.value = initCustomStartDate.value;
    } else if (
      isSameDay(start, searchDateList.value.twoAgoStart ?? today) &&
      isSameDay(end, searchDateList.value.twoAgoEnd ?? today)
    ) {
      searchDateModel.value = 'twoAgo';
      customDurationStart.value = initCustomStartDate.value;
    } else if (
      isSameDay(start, searchDateList.value.oneAgoStart ?? today) &&
      isSameDay(end, searchDateList.value.oneAgoEnd ?? today)
    ) {
      searchDateModel.value = 'oneAgo';
      customDurationStart.value = initCustomStartDate.value;
    } else {
      customDuration.value.start = start;
      customDuration.value.end = end;
      customDurationStart.value = start;
      searchDateModel.value = 'custom';
    }
  };

  const selectCustomSearchDateModel = () => {
    searchDateModel.value = 'custom';
    customCalendarId.value = customCalendarId.value + 1;
  };

  const setRegularSearchDuraution = (duraution: DateRange) => {
    regularSearchDuraution.value = duraution;
  };

  // インポート詳細情報取得API前のsearchStartDate、searchEndDate設定
  const setSearchDateBeforeFetch = () => {
    if (inputform.importCsvData.value.length === 0) {
      return;
    }
    if (
      inputform.programTypesValue.value ===
      TroProgramImportDetailFormTroProgramTypeEnum.Regular
    ) {
      if (
        !inputform.lastProgramType.value ||
        inputform.lastProgramType.value ===
          TroProgramImportDetailFormTroProgramTypeEnum.Single
      ) {
        setInitRegularSearchDate();
      }

      return;
    }

    // CSVデータから放送日と参照日付の配列を作成
    const broadcastDateIndex = inputform.importCsvData.value[0].findIndex(
      v => v === '放送日'
    );
    const analysisDateDateIndex = inputform.importCsvData.value[0].findIndex(
      v => v === '参照日付'
    );
    if (broadcastDateIndex < 0 || analysisDateDateIndex < 0) return;

    const castBroadcast = (strDate: string) => {
      if (isNaN(new Date(strDate).getTime())) return strDate;

      const spl = strDate.split('/');
      if (spl.length === 2) {
        const year = today.getFullYear();
        let date = new Date(year, Number(spl[0]) - 1, Number(spl[1]));
        if (
          today.getDate() === date.getDate() &&
          today.getMonth() === date.getMonth()
        ) {
          return format(date, DATE_FORMAT);
        }
        if (isBefore(date, today)) {
          date = addYears(date, 1);
        }
        return format(date, DATE_FORMAT);
      } else {
        return strDate;
      }
    };

    const castAnalysis = (strDate: string) => {
      if (isNaN(new Date(strDate).getTime())) return strDate;

      const spl = strDate.split('/');
      if (spl.length === 2) {
        const year = today.getFullYear();
        let date = new Date(year, Number(spl[0]) - 1, Number(spl[1]));
        if (isAfter(date, dateWithData.value.end)) {
          date = subYears(date, 1);
        }
        return format(date, DATE_FORMAT);
      } else {
        return strDate;
      }
    };

    const csvList: { broadcastDate: string; analysisDate: string }[] = [];
    inputform.importCsvData.value.map((csv, i) => {
      if (i === 0) return;
      const tmpBroadcastDate = csv[broadcastDateIndex].split(',');
      tmpBroadcastDate.map(val => {
        csvList.push({
          broadcastDate: castBroadcast(val),
          analysisDate: castAnalysis(csv[analysisDateDateIndex]) ?? ''
        });
      });
    });

    // 型判定
    let isIrregularity = false;
    csvList.map(v => {
      if (isNaN(new Date(v.broadcastDate).getTime())) isIrregularity = true;
      if (
        v.analysisDate !== null &&
        v.analysisDate !== '前年同日' &&
        v.analysisDate !== '' &&
        isNaN(new Date(v.analysisDate).getTime())
      ) {
        isIrregularity = true;
      }
    });
    if (isIrregularity) return;

    // 実績参照期間取得・設定
    setDateWithData();
    let tmpCustomPlanningStartDate = addYears(today, 100);
    let tmpCustomPlanningEndDate = subYears(today, 100);
    csvList?.map(v => {
      if (!v.analysisDate || v.analysisDate === '前年同日') {
        const bDate = new Date(v.broadcastDate);
        tmpCustomPlanningStartDate = isBefore(bDate, tmpCustomPlanningStartDate)
          ? bDate
          : tmpCustomPlanningStartDate;
        tmpCustomPlanningEndDate = isAfter(bDate, tmpCustomPlanningEndDate)
          ? bDate
          : tmpCustomPlanningEndDate;
      }
    });
    customPlanningStartDate.value = format(
      tmpCustomPlanningStartDate,
      DATE_FORMAT
    );

    customPlanningEndDate.value = format(tmpCustomPlanningEndDate, DATE_FORMAT);
    setSearchDateList(csvList);
    seDisplaySearchDateList();
    setCustomDateValues();
    customDurationStart.value = initCustomStartDate.value;
    checkAnalysisInRange();
    setSearchDate();
  };

  return {
    searchDateType,
    searchDateModel,
    searcDateSelectErr,
    searcDateCsvErr,
    planningStartDate,
    planningEndDate,
    dateWithData,
    customMax,
    displaySearchDateList,
    regularSearchDuraution,
    regularCalendarSubId,
    customDurationStart,
    customDuration,
    customCalendarId,
    selectCustomSearchDateModel,
    setSearchDate,
    setSearcDateData,
    setInitSearchDate,
    setSearchDateModel,
    setRegularSearchDuraution,
    setSearchDateBeforeFetch
  };
};

// 既存CMとの組み合わせ分析
export const useCmCombination = (
  companyId: Ref<number>,
  inputform: InputFormType
): any => {
  const cmCombinationMaxDate = computed(() => {
    return subDays(new Date(), 1);
  });
  const cmCombinationCalendarId = ref(0);
  const validCmCombinationProducts = ref<string | undefined>();

  const setCmCombinationDuration = () => {
    if (inputform.isProgramImportDetailUpdate.value) {
      const searchStartDate = new Date(inputform.searchStartDate.value);
      const searchEndDate = new Date(inputform.searchEndDate.value);
      const start = isAfter(searchStartDate, cmCombinationMaxDate.value)
        ? cmCombinationMaxDate.value
        : searchStartDate;
      const end = isAfter(searchEndDate, cmCombinationMaxDate.value)
        ? cmCombinationMaxDate.value
        : searchEndDate;
      inputform.cmCombinationDuration.value = { start: start, end: end };
    }
  };

  // 商品／ブランド取得
  const fetchProducts = async (search: string, isChangeDuration = false) => {
    const start = format(
      inputform.cmCombinationDuration.value.start ?? new Date(),
      DATE_FORMAT
    );
    const end = format(
      inputform.cmCombinationDuration.value.end ?? new Date(),
      DATE_FORMAT
    );
    if (companyId.value) {
      const res = await CompanyApi.getCompaniesCompanyIdProductsSearch(
        companyId.value,
        start,
        end,
        'CM_CREATIVE_DATE',
        undefined,
        undefined,
        search !== '' ? search : undefined
      );
      // 現在表示されている商品／ブランドが検索結果にない場合は選択値から削除
      // （参照期間のカレンダー変更契機による検索実行時のみ）
      if (isChangeDuration) {
        const list = res.data.list;
        const newProducts: Products[] = [];
        inputform.products.value.map(product => {
          if (list.find(v => v.id == product.id)) newProducts.push(product);
        });
        inputform.products.value = newProducts;
      }
      return res.data.list;
    }
  };
  interface cmCreativeDataListType extends TvDataSearchCmCreative {
    select: number;
  }
  const isLoadingFetchCmCreatives = ref(false);
  const cmCreativeDataListItems = ref<cmCreativeDataListType[]>([]);
  const selectedCmCreativeDataListItems = ref<cmCreativeDataListType[]>([]);
  // CM素材取得
  const fetchCmCreatives = async (): Promise<void> => {
    try {
      isLoadingFetchCmCreatives.value = true;
      if (!inputform.isCmCreativeOpen.value) {
        cmCreativeDataListItems.value = [];
        inputform.selectedCmCreativeIds.value = [];
        selectedCmCreativeDataListItems.value = [];
        return;
      }

      const selectedProducts = inputform.products.value;
      if (selectedProducts.length == 0) return;
      const cmProductIds: number[] = [];
      selectedProducts.map(product => {
        cmProductIds.push(product.mainCmProduct.id);
        product.relatedCmProducts.map(v => {
          cmProductIds.push(v.id);
        });
      });

      if (
        inputform.selectedCmCreativeIds.value.length > 0 &&
        cmCreativeDataListItems.value.length > 0
      ) {
        // 選択状態のリスト
        selectedCmCreativeDataListItems.value = cmCreativeDataListItems.value.filter(
          v => inputform.selectedCmCreativeIds.value.includes(v.id)
        );
      }
      const startDate = format(
        inputform.cmCombinationDuration.value.start ?? new Date(),
        DATE_FORMAT
      );
      const endDate = format(
        inputform.cmCombinationDuration.value.end ?? new Date(),
        DATE_FORMAT
      );
      if (startDate && endDate) {
        const result = await TvDataSearchApi.getTvdataSearchCmCreativesSearch(
          startDate,
          endDate,
          cmProductIds,
          companyId.value
        );
        if (200 <= result.status && result.status < 300) {
          cmCreativeDataListItems.value = result.data.map(v => {
            return {
              ...v,
              select: v.id
            };
          });
          // 前のCM素材取得処理後に選択状態であったが最新のCM素材取得処理レスポンスの一覧には含まれてないリスト
          // (画面で赤字表示するリスト)
          const notIncludedList = selectedCmCreativeDataListItems.value.filter(
            item => !result.data.map(data => data.id).includes(item.id)
          );
          inputform.notIncludedListIds.value = notIncludedList.map(v => v.id);
          cmCreativeDataListItems.value = cmCreativeDataListItems.value
            .concat(notIncludedList)
            .sort((a, b) => a.id - b.id);
        }
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: 'CM素材を取得できませんでした',
        variant: 'error'
      });
    } finally {
      isLoadingFetchCmCreatives.value = false;
    }
  };

  const cmCreativeClick = (itemId: number) => {
    if (inputform.selectedCmCreativeIds.value.includes(itemId)) {
      inputform.selectedCmCreativeIds.value = inputform.selectedCmCreativeIds.value.filter(
        id => id !== itemId
      );
    } else {
      inputform.selectedCmCreativeIds.value = [
        ...inputform.selectedCmCreativeIds.value,
        itemId
      ];
    }
  };

  const cmCombinationDurationUpdate = async (): Promise<void> => {
    const flg = inputform.products.value.length > 0;
    await fetchProducts('', true);
    validCmCombinationProducts.value =
      inputform.products.value.length === 0 && flg
        ? '選択肢の中から再度商品を選択してください'
        : validCmCombinationProducts.value;
    cmCombinationCalendarId.value = cmCombinationCalendarId.value + 1;
  };

  watch(
    inputform.products,
    value => {
      validCmCombinationProducts.value =
        value.length > 0 ? undefined : validCmCombinationProducts.value;
    },
    { deep: true }
  );

  return {
    cmCombinationMaxDate,
    setCmCombinationDuration,
    fetchProducts,
    fetchCmCreatives,
    cmCreativeClick,
    cmCombinationDurationUpdate,
    cmCombinationCalendarId,
    validCmCombinationProducts,
    isLoadingFetchCmCreatives,
    cmCreativeDataListItems,
    selectedCmCreativeDataListItems
  };
};

// キャンセル、保存、実行
export const useButtons = (
  route: ReturnType<typeof useRoute>,
  router: ReturnType<typeof useRouter>,
  companyId: Ref<number>,
  accountId: number,
  areaValue: Ref<AreaInfoIdEnum | undefined>,
  inputform: InputFormType,
  submit: (
    form: TroProjectForm,
    submitType: SubmitType,
    updateAt?: string
  ) => void
): any => {
  const cancel = (editType: EditType) => {
    if (editType == EditType.create) {
      router.go(0);
    } else {
      router.push({
        name: PLANNING_ROUTES.tro,
        params: {
          companyId: companyId.value
        }
      });
    }
  };

  const convertProgramTypes = (
    value: TroProgramImportDetailFormTroProgramTypeEnum
  ): TroProjectBasicInfoTroProgramTypeEnum => {
    switch (value) {
      case TroProgramImportDetailFormTroProgramTypeEnum.Regular:
        return TroProjectBasicInfoTroProgramTypeEnum.Regular;
      case TroProgramImportDetailFormTroProgramTypeEnum.Single:
        return TroProjectBasicInfoTroProgramTypeEnum.Single;
      default:
        return TroProjectBasicInfoTroProgramTypeEnum.Regular;
    }
  };
  const convertCombination = (
    value: TroProgramImportDetailFormTroCombinationEnum
  ): TroProjectBasicInfoTroCombinationEnum => {
    switch (value) {
      case TroProgramImportDetailFormTroCombinationEnum.Budget:
        return TroProjectBasicInfoTroCombinationEnum.Budget;
      case TroProgramImportDetailFormTroCombinationEnum.Programs:
        return TroProjectBasicInfoTroCombinationEnum.Programs;
      default:
        return TroProjectBasicInfoTroCombinationEnum.Budget;
    }
  };

  const checkCmCreatives = (): boolean => {
    let erroMsg = '';
    if (!inputform.isOpenAnalysis.value || !inputform.isCmCreativeOpen.value)
      return true;
    if (inputform.selectedCmCreativeIds.value.length === 0) {
      erroMsg =
        'CM素材が指定されていません。全ての素材を対象とする場合は、「CM素材を指定する」をOFFにしてください。';
    } else {
      const check = inputform.selectedCmCreativeIds.value.some(
        id => !inputform.notIncludedListIds.value.map(nId => nId).includes(id)
      );
      if (!check) {
        erroMsg = '有効なCM素材を選択してください。';
      }
    }
    if (erroMsg) {
      toast({ title: '失敗', message: erroMsg, variant: 'error' });
      return false;
    } else {
      return true;
    }
  };

  const clickSubmit = (submitType: SubmitType) => {
    if (!areaValue.value) return;
    if (!checkCmCreatives()) return;

    const isBudget =
      inputform.combinationValue.value ===
      TroProgramImportDetailFormTroCombinationEnum.Budget;

    const searchStatus =
      submitType == SubmitType.save
        ? TroProjectBasicInfoSearchStatusEnum.NotExecuted
        : TroProjectBasicInfoSearchStatusEnum.Searching;

    const basicInfo: TroProjectBasicInfo = {
      projectId: inputform.projectId.value ?? undefined,
      projectName: inputform.projectName.value,
      authorSaasAccountId: accountId,
      troProgramType: convertProgramTypes(inputform.programTypesValue.value),
      troCombination: convertCombination(inputform.combinationValue.value),
      area: areaValue.value,
      broadcastTimeType:
        inputform.importDataForm.broadcastTimeType.value ?? undefined,
      totalBudget: isBudget
        ? inputform.importDataForm.totalBudget.value ?? undefined
        : undefined,
      numberOfPrograms: !isBudget
        ? inputform.importDataForm.numberOfPrograms.value ?? undefined
        : undefined,
      numberOfReplaceSponsoredPrograms:
        inputform.importDataForm.numberOfReplaceSponsoredPrograms.value ??
        undefined,
      excludesSingleBroadcast:
        inputform.importDataForm.excludesSingleBroadcast.value,
      searchStartDate: inputform.searchStartDate.value,
      searchEndDate: inputform.searchEndDate.value,
      numOfEffectiveContacts: inputform.numOfEffectiveContacts.value,
      searchStatus: searchStatus
    };

    const convertTargetName = (value: string): string => {
      switch (value) {
        case TARGET_NAME.customTarget:
          return 'CUSTOM';
        case TARGET_NAME.individual:
          return 'INDIVIDUAL';
        case TARGET_NAME.genderAge12Type:
          return 'BASIC';
        case TARGET_NAME.gender10Type:
          return 'BASIC';
        default:
          return 'INDIVIDUAL';
      }
    };

    let targetSettingsTargets: TroTargetSettingsTargets[] = [];
    if (inputform.isCustomTarget.value) {
      targetSettingsTargets = [
        { targetId: Number(inputform.customTargetId.value) }
      ];
    } else {
      if (inputform.basicTargetIds.value) {
        inputform.basicTargetIds.value?.map(v => {
          targetSettingsTargets.push({
            targetId: Number(v)
          });
        });
      }
    }
    const targetSetting: TroTargetSettings = {
      targetType: convertTargetName(inputform.targetName.value),
      targets: targetSettingsTargets,
      targetIsEnabled: undefined
    };

    const convertTroPlacementStatus = (
      value: TroProgramImportDetailTroPlacementStatusEnum
    ): TroProjectProgramSettingTroPlacementStatusEnum => {
      switch (value) {
        case TroProgramImportDetailTroPlacementStatusEnum.Candidate:
          return TroProjectProgramSettingTroPlacementStatusEnum.Candidate;
        case TroProgramImportDetailTroPlacementStatusEnum.Sponsored:
          return TroProjectProgramSettingTroPlacementStatusEnum.Sponsored;
        default:
          return TroProjectProgramSettingTroPlacementStatusEnum.Candidate;
      }
    };
    const programSettings: TroProjectProgramSetting[] = inputform.importDataForm
      .programImportDetail.value
      ? inputform.importDataForm.programImportDetail.value?.list.map(v => ({
          stationCode: v.stationCode,
          startTime: v.startTime,
          endTime: v.endTime,
          programName: v.programName,
          monthlyCost: v.monthlyCost ?? undefined,
          troPlacementStatus: v.troPlacementStatus
            ? convertTroPlacementStatus(v.troPlacementStatus)
            : undefined,
          dayOfWeek: v.dayOfWeek ?? undefined,
          broadcastDate: v.broadcastDate ?? undefined,
          analysisDate: v.analysisDate ?? undefined,
          broadcasts: v.broadcasts ?? undefined,
          excludedBroadcasts: v.excludedBroadcasts ?? undefined,
          fixed: v.fixed,
          exclude: v.exclude ?? undefined
        }))
      : [];

    const referenceStartDate = format(
      inputform.cmCombinationDuration.value.start ?? new Date(),
      DATE_FORMAT
    );
    const referenceEndDate = format(
      inputform.cmCombinationDuration.value.end ?? new Date(),
      DATE_FORMAT
    );

    const cmProductIds = inputform.products?.value.map(v => ({
      id: v.id,
      isCmProduct: v.isCmProduct
    }));
    // 既存CMとの組み合わせ分析SwitchがOnの時だけ有効
    const cmCombinations: TroProjectCmCombinationForm | undefined = inputform
      .isOpenAnalysis.value
      ? {
          referenceStartDate: referenceStartDate,
          referenceEndDate: referenceEndDate,
          cmBuyingKind: inputform.cmBuyingKind.value,
          productIds: cmProductIds,
          cmCreativeIds: inputform.selectedCmCreativeIds.value ?? undefined
        }
      : undefined;

    const stationSettings: TroProjectStationSetting[] | undefined =
      inputform.importDataForm.stationSettingList.value &&
      inputform.importDataForm.conditions.value == ConditionsType.Station
        ? inputform.importDataForm.stationSettingList.value.map(v => ({
            stationCode: v.code,
            numberOfPrograms: v.value
          }))
        : undefined;

    const ProjectForm: TroProjectForm = {
      basicInfo: basicInfo,
      targetSetting: targetSetting,
      programSettings: programSettings,
      cmCombinations: cmCombinations,
      stationSettings: stationSettings
    };

    submit(ProjectForm, submitType, inputform.updatedAt.value);
  };

  return { cancel, clickSubmit };
};

// 編集・複製
export const useEditAndClone = (
  inputform: InputFormType,
  tvStations: Ref<{ code: string; label: string }[]>,
  fetchProducts: (
    search: string,
    isChangeDuration?: boolean
  ) => Promise<Products[] | undefined>,
  setSearcDateData: () => void,
  setCmCombinationDuration: () => void,
  setSearchDateModel: (start: Date, end: Date) => void,
  regularCalendarSubId: Ref<number>,
  cmCombinationCalendarId: Ref<number>,
  customCalendarId: Ref<number>,
  setRegularSearchDuraution: (duraution: DateRange) => void
): any => {
  const convertProgramType = (
    value: TroProjectBasicInfoTroProgramTypeEnum | undefined
  ): TroProgramImportDetailFormTroProgramTypeEnum => {
    switch (value) {
      case TroProjectBasicInfoTroProgramTypeEnum.Regular:
        return TroProgramImportDetailFormTroProgramTypeEnum.Regular;
      case TroProjectBasicInfoTroProgramTypeEnum.Single:
        return TroProgramImportDetailFormTroProgramTypeEnum.Single;
      default:
        return TroProgramImportDetailFormTroProgramTypeEnum.Regular;
    }
  };
  const convertCombination = (
    value: TroProjectBasicInfoTroCombinationEnum | undefined
  ): TroProgramImportDetailFormTroCombinationEnum => {
    switch (value) {
      case TroProjectBasicInfoTroCombinationEnum.Budget:
        return TroProgramImportDetailFormTroCombinationEnum.Budget;
      case TroProjectBasicInfoTroCombinationEnum.Programs:
        return TroProgramImportDetailFormTroCombinationEnum.Programs;
      default:
        return TroProgramImportDetailFormTroCombinationEnum.Budget;
    }
  };

  const getTargetName = (targetType: string) => {
    switch (targetType) {
      case 'CUSTOM':
        return TARGET_NAME.customTarget;
      case 'INDIVIDUAL':
        return TARGET_NAME.individual;
      case 'BASIC_GA12':
        return TARGET_NAME.genderAge12Type;
      case 'BASIC_GA10S':
        return TARGET_NAME.gender10Type;
      default:
        return TARGET_NAME.individual;
    }
  };

  const convertToInputFormData = async (data: TroProjectDetail | undefined) => {
    if (!data) return;

    inputform.programTypesValue.value = convertProgramType(
      data.basicInfo.troProgramType
    );
    inputform.lastProgramType.value = inputform.programTypesValue.value;
    inputform.combinationValue.value = convertCombination(
      data.basicInfo.troCombination
    );
    inputform.projectId.value = data.basicInfo.projectId;
    inputform.projectName.value = data.basicInfo.projectName;
    inputform.searchStatus.value = data.basicInfo.searchStatus;
    inputform.importDataForm.broadcastTimeType.value =
      data.basicInfo.broadcastTimeType ??
      TroProjectBasicInfoBroadcastTimeTypeEnum.ImportData;
    inputform.importDataForm.excludesSingleBroadcast.value =
      data.basicInfo.excludesSingleBroadcast;

    const today = new Date();
    const list: ExTroProgramImportDetail[] = data.programSettings.map(v => ({
      troPlacementStatus: v.troPlacementStatus,
      stationCode: v.stationCode,
      dayOfWeek: v.dayOfWeek,
      broadcastDate: v.broadcastDate,
      analysisDate: v.analysisDate,
      startTime: v.startTime,
      endTime: v.endTime,
      programName: v.programName,
      monthlyCost: v.monthlyCost,
      fixed: v.fixed,
      exclude: v.exclude,
      broadcastListCount: v.broadcastList?.length,
      formatedBroadcastDate: new Date(v.broadcastDate ?? today),
      broadcastList: v.broadcastList?.map(broadcast => ({
        ...broadcast
      })),
      broadcasts:
        v.broadcastList
          ?.filter(broadcast => !v.excludeBroadcastList?.includes(broadcast.id))
          .map(broadcast => broadcast.id) ?? [],
      excludedBroadcasts: v.excludeBroadcastList?.map(exclude => exclude) ?? [],
      isBroadcastListError: false
    }));
    inputform.importDataForm.programImportDetail.value = {
      totalCount: list.length,
      list: list
    };
    inputform.isProgramImportDetailUpdate.value = true;

    inputform.importDataForm.totalBudget.value = data.basicInfo.totalBudget;
    inputform.importDataForm.numberOfPrograms.value =
      data.basicInfo.numberOfPrograms;
    inputform.importDataForm.stationSettingList.value = tvStations.value.map(
      v => ({
        ...v,
        value:
          data.stationSettings?.find(setting => setting.stationCode == v.code)
            ?.numberOfPrograms ?? 0,
        valueItems: []
      })
    );
    inputform.importDataForm.numberOfReplaceSponsoredPrograms.value =
      data.basicInfo.numberOfReplaceSponsoredPrograms;
    if (data.stationSettings?.some(v => v.numberOfPrograms > 0)) {
      inputform.importDataForm.conditions.value = ConditionsType.Station;
    } else if (data.basicInfo.numberOfReplaceSponsoredPrograms ?? 0 > 0) {
      inputform.importDataForm.conditions.value = ConditionsType.Replace;
    } else {
      inputform.importDataForm.conditions.value = ConditionsType.None;
    }
    inputform.isOpenimportDataForm.value = true;

    if (
      inputform.programTypesValue.value ==
      TroProgramImportDetailFormTroProgramTypeEnum.Single
    ) {
      setSearcDateData();
      inputform.searchStartDate.value = data.basicInfo.searchStartDate;
      inputform.searchEndDate.value = data.basicInfo.searchEndDate;
      setCmCombinationDuration();
      const start = new Date(data.basicInfo.searchStartDate ?? today);
      const end = new Date(data.basicInfo.searchEndDate ?? today);
      setSearchDateModel(start, end);
      regularCalendarSubId.value = regularCalendarSubId.value + 1;
      cmCombinationCalendarId.value = cmCombinationCalendarId.value + 1;
      customCalendarId.value = customCalendarId.value + 1;
    } else {
      const regularSearchDuraution = {
        start: new Date(data.basicInfo.searchStartDate ?? today),
        end: new Date(data.basicInfo.searchEndDate ?? today)
      };
      setRegularSearchDuraution(regularSearchDuraution);
      inputform.cmCombinationDuration.value = {
        start: new Date(data.basicInfo.searchStartDate),
        end: new Date(data.basicInfo.searchEndDate)
      };
    }

    inputform.targetName.value = getTargetName(data.targetSetting.targetType);
    if (data.targetSetting.targetType == 'CUSTOM') {
      inputform.customTargetId.value = data.targetSetting.targets[0].targetId.toString();
      inputform.isCustomTarget.value = true;
    } else if (data.targetSetting.targetType == 'INDIVIDUAL') {
      inputform.isCustomTarget.value = false;
    } else {
      inputform.basicTargetIds.value = data.targetSetting.targets.map(v => {
        return v.targetId.toString() ?? '0';
      });
      inputform.isCustomTarget.value = false;
    }
    inputform.initialTargetId.value = data.targetSetting.targets.map(
      v => v.targetId
    );

    inputform.numOfEffectiveContacts.value =
      data.basicInfo.numOfEffectiveContacts;
    inputform.searchStartDate.value = data.basicInfo.searchStartDate;
    inputform.searchEndDate.value = data.basicInfo.searchEndDate;

    if (
      data.cmCombinations?.productIds &&
      data.cmCombinations?.productIds.length > 0
    ) {
      inputform.isOpenAnalysis.value = true;
      inputform.cmCombinationDuration.value = {
        start: new Date(data.cmCombinations.referenceStartDate),
        end: new Date(data.cmCombinations.referenceEndDate)
      };
      const products = await fetchProducts('');
      inputform.cmBuyingKind.value = data.cmCombinations.cmBuyingKind;
      inputform.products.value =
        products?.filter(v => data.cmCombinations?.productIds.includes(v.id)) ??
        [];
      if (
        data.cmCombinations?.cmCreativeIds &&
        data.cmCombinations?.cmCreativeIds.length > 0
      ) {
        inputform.isCmCreativeOpen.value = true;
        inputform.selectedCmCreativeIds.value = data.cmCombinations?.cmCreativeIds.map(
          v => v
        );
        inputform.notIncludedListIds.value = [];
      }
    }

    inputform.csvDownloadState.value = 'OFF';
    inputform.authorSaasAccountId.value = data.basicInfo.authorSaasAccountId;
    inputform.updatedAt.value = data.updatedAt;
  };

  return { convertToInputFormData };
};
