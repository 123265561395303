/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TroApi } from '@/api';
import { TroProjectDetail, TroProjectForm } from '@/api/openapi';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { useCreateProject } from '@/composables/planning/tro/create';
import { SubmitType } from '@/composables/planning/tro/inputForm';
import { PLANNING_ROUTES, ROUTE_NAMES } from '@/router';
import Axios from 'axios';
import { isAfter } from 'date-fns';
import { Ref, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

type updateProjectReturnTypes = {
  isOpenModal: Ref<boolean>;
  fetchProject: (params: any) => Promise<TroProjectDetail | undefined>;
  isFetchingProject: Ref<boolean>;
  updateProject: (values: any) => Promise<void>;
  isUpdatingProject: Ref<boolean>;
  forceCreateProject: (params: unknown) => Promise<void>;
  isForceCreatingProject: Ref<boolean>;
  gotoProjectListPage: () => Promise<void>;
  addCopyString: (value: string) => string;
};

export const useUpdateProject = (): updateProjectReturnTypes => {
  const router = useRouter();
  const route = useRoute();
  const companyId = route.params.companyId?.toString();
  if (!companyId) {
    toast({ title: 'カンパニーが選択されていません', variant: 'error' });
  }
  const projectId = route.params.projectId?.toString();
  const { projectSearch, createProject } = useCreateProject();
  const isOpenModal = ref(false);
  const forceForm: Ref<TroProjectForm | undefined> = ref();
  const forceDataSubmitType = ref(SubmitType.save);
  const addCopyString = (value: string) => {
    const str = value.length > 60 ? value.substring(0, 60) : value;
    return str + ' コピー';
  };

  const _fetchProject = async (): Promise<TroProjectDetail | undefined> => {
    try {
      const res = await TroApi.getCmPlanningTroProjectsTroProjectId(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        return res.data;
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e, 'プロジェクトの更新に失敗しました', '');
      } else if (e.status == 403) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの更新に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの更新に失敗しました',
          variant: 'error'
        });
      }
    }
  };

  const [isFetchingProject, fetchProject] = useLoading(_fetchProject);

  const _forceCreateProject = async () => {
    try {
      const form = forceForm.value;
      if (form) {
        form.basicInfo.projectName = addCopyString(
          form?.basicInfo?.projectName
        );
      }
      const submitType = forceDataSubmitType.value;
      await createProject({ form, submitType, isForce: true });
    } catch (e) {
      handleError(e, 'プロジェクトの更新に失敗しました', '');
    } finally {
      forceForm.value = undefined;
    }
  };
  const [isForceCreatingProject, forceCreateProject] = useLoading(
    _forceCreateProject
  );

  const _updateProject = async ({
    form,
    submitType,
    updateAt
  }: {
    form: TroProjectForm;
    submitType: SubmitType;
    updateAt: string;
  }) => {
    try {
      // 上書き確認
      const latestData = await fetchProject(form.basicInfo.projectId);
      const latestDataUpdateAt = new Date(
        latestData?.updatedAt.replace(/-/g, '/') ?? new Date()
      );
      if (!latestData) return;

      const editDataUpdateAt = new Date(updateAt.replace(/-/g, '/'));
      if (isAfter(latestDataUpdateAt, editDataUpdateAt)) {
        isOpenModal.value = true;
        forceForm.value = form;
        forceDataSubmitType.value = submitType;
        return;
      }

      // プロジェクト更新
      const res = await TroApi.putCmPlanningTroProjectsTroProjectId(
        form.basicInfo.projectId ?? 0,
        form
      );
      if (200 <= res.status && res.status < 300) {
        toast({
          title: '成功',
          message: 'プロジェクトの更新に成功しました',
          variant: 'success'
        });
        if (submitType == SubmitType.save) {
          await router.push({
            name: PLANNING_ROUTES.tro,
            params: route.params
          });
        } else {
          const projectId = res.data.basicInfo.projectId ?? 0;
          const searchRes = await projectSearch(projectId);
          if (searchRes) {
            await router.push({
              name: PLANNING_ROUTES.tro,
              params: route.params
            });
          }
        }
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの更新に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e);
      } else if (e.status === 403) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの更新に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの更新に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isUpdatingProject, updateProject] = useLoading(_updateProject);

  const gotoProjectListPage = async () => {
    await router.push({
      name: PLANNING_ROUTES.tro,
      params: route.params
    });
  };

  return {
    isOpenModal,
    fetchProject,
    isFetchingProject,
    updateProject,
    isUpdatingProject,
    forceCreateProject,
    isForceCreatingProject,
    gotoProjectListPage,
    addCopyString
  };
};
