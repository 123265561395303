<template>
  <Box class="loading-box" text-align="center">
    <div class="message">
      <ThreeDotsSvg class="loading" />
      <Typography size="s" color="primary" line-height="1.5" class="mt-6">
        {{ message }}
      </Typography>
      <Typography size="s" color="primary" line-height="1.5" class="mt-2">
        {{ subMessage }}
      </Typography>
    </div>
  </Box>
</template>

<script>
import { defineComponent } from 'vue';
import ThreeDotsSvg from '@/assets/images/three-dots.svg?inline';
import Box from '@/components/layout/Box.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'LoadingMessage',
  components: {
    ThreeDotsSvg,
    Box,
    Typography
  },
  props: {
    message: {
      type: String,
      default: 'データを検索中です'
    },
    subMessage: {
      type: String,
      default: '読み込み完了まで、最大1分程度かかる場合があります'
    }
  }
});
</script>

<style lang="scss">
@import '@/styles/layout';

.loading-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-clickgurard + 1;
  background-color: var(--contrast-90-color);
  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ui-typography.primary,
  .loading {
    color: var(--chart-blue-2);
    height: 10px;
  }
}
</style>
