/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TroProgramBroadcastTroProgramTypeEnum,
  TroProgramImportDetail,
  TroProgramImportDetailFormTroCombinationEnum,
  TroProgramImportDetailFormTroProgramTypeEnum,
  TroProgramImportDetailTroPlacementStatusEnum,
  TroProjectBasicInfoBroadcastTimeTypeEnum
} from '@/api/openapi';
import { roundNumber } from '@/common/formatter';
import {
  EXCEED_TOTAL_COST_BY_FIXED_PROGRAMS,
  EXCEED_TOTAL_COST_BY_FIXED_STATIONS
} from '@/common/messages';
import { VALIDATION_MESSAGE } from '@/common/validation';
import {
  getBroadcastIds,
  judgeSplitTimeRange
} from '@/composables/planning/tro/broadcastListModal';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { Ref, computed, inject, ref, watch } from 'vue';

export type stationSettingListType =
  | Array<{
      code: string;
      label: string;
      value: number;
      valueItems: number[];
    }>
  | undefined;
export enum ConditionsType {
  None,
  Station,
  Replace
}

export interface ExTroProgramImportDetail extends TroProgramImportDetail {
  broadcastListCount: number | undefined;
  formatedBroadcastDate: Date;
  broadcasts: number[];
  excludedBroadcasts: number[];
  isBroadcastListError: boolean;
}

export interface ExTroProgramImportDetailList {
  totalCount: number;
  list: ExTroProgramImportDetail[];
}

export interface ImportDataFormType {
  programImportDetail: Ref<ExTroProgramImportDetailList | undefined>;
  broadcastTimeType: Ref<TroProjectBasicInfoBroadcastTimeTypeEnum>;
  excludesSingleBroadcast: Ref<boolean>;
  totalBudget: Ref<number | undefined>;
  numberOfPrograms: Ref<number | undefined>;
  stationSettingList: Ref<stationSettingListType>;
  numberOfReplaceSponsoredPrograms: Ref<number | undefined>;
  conditions: Ref<ConditionsType>;
  isError: Ref<boolean>;
}

export const useImportData = (
  form: ImportDataFormType,
  isBudget: boolean
): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $papa: any = inject('$papa');

  const localizeTroPlacementStatus = (
    value: TroProgramImportDetailTroPlacementStatusEnum | undefined
  ) => {
    switch (value) {
      case TroProgramImportDetailTroPlacementStatusEnum.Candidate:
        return '候補';
      case TroProgramImportDetailTroPlacementStatusEnum.Sponsored:
        return '提供中';
      default:
        return '候補';
    }
  };

  const getMax = (a, b) => {
    return Math.max(a, b);
  };
  const getMin = (a, b) => {
    return Math.min(a, b);
  };

  const programImportDetail = computed({
    get: () => form.programImportDetail.value,
    set: value => (form.programImportDetail.value = value)
  });

  const convertTableData = ref(programImportDetail.value?.list);
  watch(
    convertTableData,
    val => {
      const copy = val;
      copy?.map(v => {
        return Object.values(v);
      });
      programImportDetail.value = {
        totalCount: programImportDetail.value?.totalCount ?? 0,
        list: copy ?? []
      };
    },
    { deep: true }
  );

  const isBroadcastTimeType = computed({
    get: () =>
      form.broadcastTimeType.value ===
      TroProjectBasicInfoBroadcastTimeTypeEnum.BroadcastTimes,
    set: value =>
      (form.broadcastTimeType.value = value
        ? TroProjectBasicInfoBroadcastTimeTypeEnum.BroadcastTimes
        : TroProjectBasicInfoBroadcastTimeTypeEnum.ImportData)
  });
  const excludesSingleBroadcast = computed({
    get: () => form.excludesSingleBroadcast.value,
    set: value => (form.excludesSingleBroadcast.value = value)
  });
  const totalCost = ref(roundNumber(form.totalBudget.value ?? 0).toString());
  watch(totalCost, val => {
    form.totalBudget.value = Number(val.replace(/[^0-9]/g, ''));
  });
  const numberOfProgramsItemsMax = computed(() => {
    const sum = convertTableData.value?.length ?? 0;
    const excludeCount =
      convertTableData.value?.filter(v => v.exclude).length ?? 0;
    let max = sum - excludeCount;
    if (conditions.value == ConditionsType.Replace) {
      let tmpMax = sum;
      for (let i = 0; i < sum; i += 1) {
        tmpMax = sum - i;
        const norspItems = calcNumberOfReplaceSponsoredProgramsItems(tmpMax);
        if (
          numberOfReplaceSponsoredPrograms.value &&
          norspItems.includes(numberOfReplaceSponsoredPrograms.value) &&
          tmpMax <= max
        ) {
          max = tmpMax;
          break;
        }
      }
    }
    return max == 0 ? 1 : max;
  });
  const numberOfProgramsItemsMin = computed(() => {
    let min = convertTableData.value?.filter(v => v.fixed).length ?? 0;
    if (conditions.value == ConditionsType.Station) {
      let sumStationValues = 0;
      stationSettingList.value?.map(v => {
        sumStationValues = sumStationValues + v.value;
      });
      min = min < sumStationValues ? sumStationValues : min;
    } else if (conditions.value == ConditionsType.Replace) {
      const sum = convertTableData.value?.length ?? 0;
      for (let i = 0; i < sum; i += 1) {
        const norspItems = calcNumberOfReplaceSponsoredProgramsItems(i);
        if (
          numberOfReplaceSponsoredPrograms.value &&
          norspItems.includes(numberOfReplaceSponsoredPrograms.value) &&
          min <= i
        ) {
          min = i;
          break;
        }
      }
    }
    return min === 0 ? 1 : min;
  });
  const numberOfPrograms = computed({
    get: () => form.numberOfPrograms.value ?? numberOfProgramsItemsMax.value,
    set: value => (form.numberOfPrograms.value = value)
  });
  const numberOfProgramsItems = computed(() => {
    const items: number[] = [];
    for (
      let i = numberOfProgramsItemsMin.value;
      i <= numberOfProgramsItemsMax.value;
      i += 1
    ) {
      items.push(i);
    }
    return items;
  });
  const stationSettingList = computed({
    get: () =>
      form.stationSettingList.value?.map(v => ({
        ...v,
        valueItems: getStationProgramsItems(v.code),
        value: setStationSettingListValue(v.value, v.code)
      })),
    set: value => (form.stationSettingList.value = value)
  });
  const numberOfReplaceSponsoredPrograms = computed({
    get: () => form.numberOfReplaceSponsoredPrograms.value,
    set: value => (form.numberOfReplaceSponsoredPrograms.value = value)
  });
  const conditions = computed({
    get: () => form.conditions.value,
    set: value => (form.conditions.value = value)
  });
  const isError = computed({
    get: () => form.isError.value,
    set: value => (form.isError.value = value)
  });
  const isBListError = computed(() => {
    const isBroadcastListErrorList = convertTableData.value?.filter(
      v => v.isBroadcastListError
    );
    return (isBroadcastListErrorList?.length ?? 0) > 0;
  });

  const setStationSettingListValue = (n: number, code: string) => {
    const items = getStationProgramsItems(code);
    if (items.length === 0) return n;
    const max = items.reduce(getMax);
    const min = items.reduce(getMin);
    let value = max < n ? max : n;
    value = value < min ? min : value;
    return value;
  };

  const getStationProgramsMax = stationCode => {
    const a =
      convertTableData.value?.filter(
        v => v.stationCode == stationCode && !v.exclude
      ).length ?? 0;
    let sumAnotherStationValue = 0;
    stationSettingList.value?.map(v => {
      if (v.code != stationCode) {
        sumAnotherStationValue = sumAnotherStationValue + v.value;
      }
    });
    const sumAtherStationFixedCount =
      convertTableData.value?.filter(
        v => v.stationCode != stationCode && v.fixed
      ).length ?? 0;
    const bigger =
      sumAnotherStationValue < sumAtherStationFixedCount
        ? sumAtherStationFixedCount
        : sumAnotherStationValue;
    const programs = isBudget
      ? convertTableData.value?.length ?? 0
      : numberOfPrograms.value;
    const b = programs - bigger;
    return a < b ? a : b;
  };

  const getStationProgramsItems = stationCode => {
    const min = 0;
    const max = getStationProgramsMax(stationCode);
    const items: number[] = [];
    for (let i = min; i <= max; i += 1) {
      items.push(i);
    }
    return items;
  };

  const candidateCount = computed(() => {
    return convertTableData.value?.filter(
      v =>
        v.troPlacementStatus ==
        TroProgramImportDetailTroPlacementStatusEnum.Candidate
    ).length;
  });
  const candidateFixedCount = computed(() => {
    return convertTableData.value?.filter(
      v =>
        v.fixed &&
        v.troPlacementStatus ==
          TroProgramImportDetailTroPlacementStatusEnum.Candidate
    ).length;
  });
  const candidateExcludeCount = computed(() => {
    return convertTableData.value?.filter(
      v =>
        v.exclude &&
        v.troPlacementStatus ==
          TroProgramImportDetailTroPlacementStatusEnum.Candidate
    ).length;
  });
  const sponsoredCount = computed(() => {
    return convertTableData.value?.filter(
      v =>
        v.troPlacementStatus ==
        TroProgramImportDetailTroPlacementStatusEnum.Sponsored
    ).length;
  });
  const sponsoredFixedCount = computed(() => {
    return convertTableData.value?.filter(
      v =>
        v.fixed &&
        v.troPlacementStatus ==
          TroProgramImportDetailTroPlacementStatusEnum.Sponsored
    ).length;
  });
  const sponsoredExcludeCount = computed(() => {
    return convertTableData.value?.filter(
      v =>
        v.exclude &&
        v.troPlacementStatus ==
          TroProgramImportDetailTroPlacementStatusEnum.Sponsored
    ).length;
  });
  const singleCandidateCount = computed(() => {
    return convertTableData.value?.length;
  });
  const fixedCount = computed(() => {
    return convertTableData.value?.filter(v => v.fixed).length;
  });
  const excludeCount = computed(() => {
    return convertTableData.value?.filter(v => v.exclude).length;
  });
  const fixedProgramsCost = computed(() => {
    return convertTableData.value
      ?.filter(v => v.fixed)
      .reduce((total, v) => total + (v.monthlyCost || 0), 0);
  });

  /**
   * stationCodeごとにMonthlyCostを昇順ソートしたデータ
   */
  const sortedMonthlyCostByStation = (() => {
    const stationMap = new Map<string, number[]>();
    programImportDetail.value?.list.forEach(({ stationCode, monthlyCost }) => {
      if (!monthlyCost) return;

      const monthlyCostList = stationMap.get(stationCode);
      if (monthlyCostList) {
        monthlyCostList.push(monthlyCost);
      } else {
        stationMap.set(stationCode, [monthlyCost]);
      }
    });

    stationMap.forEach(monthlyCostList => {
      monthlyCostList.sort((a, b) => a - b);
    });

    return stationMap;
  })();

  /**
   * 「局ごとの採用番組数」で指定された番組数分の、局ごとの提供金額合計値の最大値
   */
  const maxCostForFixedStation = computed(() => {
    let max = 0;
    stationSettingList.value?.forEach(stationSetting => {
      const stationCostList = sortedMonthlyCostByStation.get(
        stationSetting.code
      );
      if (!stationCostList) return;

      let total = 0;
      for (let i = 0; i < stationSetting.value; i++) {
        total += stationCostList[i];
      }
      max = Math.max(max, total);
    });
    return max;
  });

  const placementStatusFilterItems = computed(() => {
    const list = convertTableData.value?.map(v => v.troPlacementStatus);
    return list?.filter((v, index) => list.indexOf(v) === index);
  });
  const stationCodeFilterItems = computed(() => {
    const list = convertTableData.value?.map(v => v.stationCode);
    return list?.filter((v, index) => list.indexOf(v) === index);
  });

  const calcNumberOfReplaceSponsoredProgramsItems = (
    numberOfPrograms: number
  ) => {
    let max = 0;
    let min = 0;
    // numberOfPrograms.value 番組数
    // 提供中番組数
    const sponsoredCountVal = sponsoredCount.value ?? 0;
    // 提供中「採用」番組数
    const sponsoredFixedCountVal = sponsoredFixedCount.value ?? 0;
    // 提供中「除外」番組数
    const sponsoredExcludeCountVal = sponsoredExcludeCount.value ?? 0;
    // 候補番組数
    const candidateCountVal = candidateCount.value ?? 0;
    // 候補「除外」番組数
    const candidateExcludeCountVal = candidateExcludeCount.value ?? 0;
    // 候補「採用」番組数
    const candidateFixedCountVal = candidateFixedCount.value ?? 0;
    if (numberOfPrograms <= sponsoredCountVal) {
      const maxA = candidateCountVal - candidateExcludeCountVal;
      const maxB = numberOfPrograms - sponsoredFixedCountVal;
      max = maxA < maxB ? maxA : maxB;
      min = candidateFixedCountVal < 1 ? 1 : candidateFixedCountVal;
    } else {
      const maxA =
        sponsoredCountVal +
        candidateCountVal -
        candidateExcludeCountVal -
        numberOfPrograms;
      const maxB = numberOfPrograms - sponsoredFixedCountVal;
      max = maxA < maxB ? maxA : maxB;
      const minA =
        sponsoredCountVal + candidateFixedCountVal - numberOfPrograms;
      const minB = sponsoredExcludeCountVal;
      min = minA < minB ? minB : minA;
      min = min < 1 ? 1 : min;
    }
    min = min < max ? min : max;
    const items: number[] = [];
    for (let i = min; i <= max; i += 1) {
      items.push(i);
    }
    return items;
  };

  const numberOfReplaceSponsoredProgramsItems = computed(() => {
    return calcNumberOfReplaceSponsoredProgramsItems(numberOfPrograms.value);
  });

  const judgeFixedDisabled = (
    isFixed: boolean,
    isExclude: boolean
  ): boolean => {
    const fixedCountVal = fixedCount.value ?? 0;
    const numberOfReplaceSponsoredProgramsVal =
      numberOfReplaceSponsoredPrograms.value ?? 0;
    if (isExclude) return true;
    if (fixedCountVal === numberOfPrograms.value && !isFixed) return true;
    if (
      conditions.value == ConditionsType.Replace &&
      fixedCountVal >= numberOfReplaceSponsoredProgramsVal
    ) {
      return true;
    }
    return false;
  };

  const judgeExcludedDisabled = (
    isFixed: boolean,
    isExclude: boolean,
    stationCode: string,
    stationValue: number
  ): boolean => {
    const excludeCountVal = excludeCount.value ?? 0;
    if (isFixed) return true;
    const sponsoredAndCandidate = convertTableData.value?.length ?? 0;
    const notExclude = sponsoredAndCandidate - excludeCountVal;
    if (!isBudget && notExclude <= numberOfPrograms.value && !isExclude)
      return true;
    if (conditions.value == ConditionsType.Station) {
      const stationCount =
        convertTableData.value?.filter(v => v.stationCode == stationCode)
          .length ?? 0;
      const stationExcludedCount =
        convertTableData.value?.filter(
          v => v.stationCode == stationCode && v.exclude
        ).length ?? 0;
      if (stationCount - stationExcludedCount == stationValue && !isExclude)
        return true;
    }
    return false;
  };

  const updateIsBroadcastListError = () => {
    if (convertTableData.value) {
      convertTableData.value = setIsBroadcastListError(convertTableData.value);
    }
  };

  watch(
    () => [numberOfProgramsItemsMax, numberOfProgramsItemsMin],
    () => {
      const min = numberOfProgramsItemsMin.value;
      const max = numberOfProgramsItemsMax.value;
      if (numberOfPrograms.value < min) numberOfPrograms.value = min;
      if (max < numberOfPrograms.value) numberOfPrograms.value = max;
    },
    { deep: true }
  );

  const exportCsvFile = (
    programType: TroProgramImportDetailFormTroProgramTypeEnum,
    combination: TroProgramImportDetailFormTroCombinationEnum,
    dt: Ref,
    startDate: string,
    endDate: string,
    projectId?: number
  ) => {
    const csvHeader = createCsvHeader(programType, combination);

    const csvBody = createCsvBody(programType, combination, dt.value.value);

    const formattedStartDate = startDate.replaceAll('-', '');
    const formattedEndDate = endDate.replaceAll('-', '');

    const link = document.createElement('a');
    const fileName = !projectId
      ? `TVAL_TROPGLIST_${formattedStartDate}-${formattedEndDate}.csv`
      : `TVAL_TROPGLIST_${projectId}_${formattedStartDate}-${formattedEndDate}.csv`;
    const blob = new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]),
        $papa.unparse({ data: [...csvHeader.concat(csvBody)] })
      ],
      { type: 'text/csv' }
    );

    link.setAttribute('download', fileName);
    link.setAttribute('href', window.webkitURL.createObjectURL(blob));
    link.click();
  };

  return {
    convertTableData,
    isBroadcastTimeType,
    excludesSingleBroadcast,
    totalCost,
    numberOfPrograms,
    stationSettingList,
    numberOfReplaceSponsoredPrograms,
    numberOfReplaceSponsoredProgramsItems,
    conditions,
    isError,
    isBListError,
    candidateCount,
    candidateFixedCount,
    candidateExcludeCount,
    sponsoredCount,
    sponsoredFixedCount,
    sponsoredExcludeCount,
    singleCandidateCount,
    fixedCount,
    fixedProgramsCost,
    maxCostForFixedStation,
    placementStatusFilterItems,
    stationCodeFilterItems,
    localizeTroPlacementStatus,
    numberOfProgramsItems,
    exportCsvFile,
    judgeFixedDisabled,
    judgeExcludedDisabled,
    updateIsBroadcastListError
  };
};

/**
 * CSVのヘッダー部分を作成する。
 * @param programType 番組タイプ
 * @param combination 組み合わせ
 */
export const createCsvHeader = (
  programType: TroProgramImportDetailFormTroProgramTypeEnum,
  combination: TroProgramImportDetailFormTroCombinationEnum
): Array<Array<string>> => {
  const headers: Array<string> = [];

  if (programType === TroProgramImportDetailFormTroProgramTypeEnum.Regular) {
    headers.push('分類');
    headers.push('放送局');
    headers.push('提供曜日');
    headers.push('開始時刻');
    headers.push('終了時刻');
    headers.push('番組名');
    if (combination === TroProgramImportDetailFormTroCombinationEnum.Budget) {
      headers.push('提供金額（千円）');
    }
    headers.push('採用');
    headers.push('除外');
  }

  if (programType === TroProgramImportDetailFormTroProgramTypeEnum.Single) {
    headers.push('放送局');
    headers.push('放送日');
    headers.push('参照日付');
    headers.push('開始時刻');
    headers.push('終了時刻');
    headers.push('番組名');
    if (combination === TroProgramImportDetailFormTroCombinationEnum.Budget) {
      headers.push('提供金額（千円）');
    }
    headers.push('採用');
  }

  return [headers];
};

/**
 * CSVのボディ（データ）部分を作成する。
 * @param programType 番組タイプ
 * @param combination 組み合わせ
 * @param dt DataTableインスタンス
 */
export const createCsvBody = (
  programType: TroProgramImportDetailFormTroProgramTypeEnum,
  combination: TroProgramImportDetailFormTroCombinationEnum,
  dt: any
): Array<Array<string>> => {
  const baseData = JSON.parse(JSON.stringify(dt));

  if (programType === TroProgramImportDetailFormTroProgramTypeEnum.Regular) {
    return baseData.map(v => {
      const row: Array<string> = [];
      row.push(
        v.troPlacementStatus ===
          TroProgramImportDetailTroPlacementStatusEnum.Sponsored
          ? '0'
          : '1'
      );
      row.push(v.stationCode);
      row.push(v.dayOfWeek);
      row.push(v.startTime.slice(0, -3));
      row.push(v.endTime.slice(0, -3));
      row.push(v.programName);
      if (combination === TroProgramImportDetailFormTroCombinationEnum.Budget) {
        row.push(v.monthlyCost.toLocaleString());
      }
      row.push(v.fixed ? '1' : '0');
      row.push(v.exclude ? '1' : '0');
      return row;
    });
  }

  if (programType === TroProgramImportDetailFormTroProgramTypeEnum.Single) {
    return baseData.map(v => {
      const row: Array<string> = [];
      row.push(v.stationCode);
      const broadcastDate = new Date(v.formatedBroadcastDate);
      row.push(
        `${broadcastDate.getFullYear()}/${String(
          broadcastDate.getMonth() + 1
        ).padStart(2, '0')}/${String(broadcastDate.getDate()).padStart(2, '0')}`
      );
      if (isValidDateString(v.analysisDate)) {
        const analysisDate = new Date(v.analysisDate);
        row.push(
          `${analysisDate.getFullYear()}/${String(
            analysisDate.getMonth() + 1
          ).padStart(2, '0')}/${String(analysisDate.getDate()).padStart(
            2,
            '0'
          )}`
        );
      } else {
        row.push(v.analysisDate || '');
      }
      row.push(v.startTime.slice(0, -3));
      row.push(v.endTime.slice(0, -3));
      row.push(v.programName);
      if (combination === TroProgramImportDetailFormTroCombinationEnum.Budget) {
        row.push(v.monthlyCost.toLocaleString());
      }
      row.push(v.fixed ? '1' : '0');
      return row;
    });
  }

  return [];
};

export const getIsBroadcastError = (
  data: ExTroProgramImportDetail
): boolean => {
  return judgeSplitTimeRange(
    data.broadcastList,
    data.excludedBroadcasts,
    data.dayOfWeek ?? '',
    data.startTime,
    data.endTime,
    data.exclude ?? true
  );
};

export const setIsBroadcastListError = (
  data: ExTroProgramImportDetail[]
): ExTroProgramImportDetail[] => {
  return data.map(v => ({
    ...v,
    isBroadcastListError: judgeSplitTimeRange(
      v.broadcastList,
      v.excludedBroadcasts,
      v.dayOfWeek ?? '',
      v.startTime,
      v.endTime,
      v.exclude ?? true
    )
  }));
};

const isValidDateString = dateString => {
  return !isNaN(Date.parse(dateString));
};

export const setBroadcastIds = (
  isExcludesSingleBroadcast: boolean,
  isBroadcastTime: boolean,
  dataList: ExTroProgramImportDetail[]
): ExTroProgramImportDetail[] => {
  if (isExcludesSingleBroadcast) {
    // 単発回を全て除外する true
    if (isBroadcastTime) {
      // 時間固定true
      return dataList.map(data => ({
        ...data,
        broadcasts: getBroadcastIds(
          data.broadcastList,
          data.startTime,
          data.endTime,
          false
        ),
        excludedBroadcasts: getBroadcastIds(
          data.broadcastList,
          data.startTime,
          data.endTime,
          true
        )
      }));
    } else {
      // 時間固定false
      return dataList.map(data => ({
        ...data,
        broadcasts:
          data.broadcastList
            ?.filter(
              broadcast =>
                broadcast.troProgramType !==
                TroProgramBroadcastTroProgramTypeEnum.Single
            )
            .map(broadcast => broadcast.id) ?? [],
        excludedBroadcasts:
          data.broadcastList
            ?.filter(
              broadcast =>
                broadcast.troProgramType ===
                TroProgramBroadcastTroProgramTypeEnum.Single
            )
            .map(broadcast => broadcast.id) ?? []
      }));
    }
  } else {
    return dataList.map(data => ({
      ...data,
      broadcasts: data.broadcastList?.map(broadcast => broadcast.id) ?? [],
      excludedBroadcasts: []
    }));
  }
};

export const useDataTable = (): any => {
  const textFilterMatchModes = ref([
    { label: '等しい', value: FilterMatchMode.EQUALS },
    { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
    { label: '含む', value: FilterMatchMode.CONTAINS },
    { label: '含まない', value: FilterMatchMode.NOT_CONTAINS }
  ]);
  const numberFilterMatchModes = ref([
    { label: '等しい', value: FilterMatchMode.EQUALS },
    { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
    { label: '以上', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
    { label: '以下', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    { label: 'より大きい', value: FilterMatchMode.GREATER_THAN },
    { label: 'より小さい', value: FilterMatchMode.LESS_THAN }
  ]);
  const dateFilterMatchModes = ref([
    { label: '等しい', value: FilterMatchMode.DATE_IS },
    { label: '含まない', value: FilterMatchMode.DATE_IS_NOT },
    { label: '以前', value: FilterMatchMode.DATE_BEFORE },
    { label: 'より後', value: FilterMatchMode.DATE_AFTER }
  ]);
  const filters = ref({
    fixed: { value: null, matchMode: FilterMatchMode.IN },
    exclude: { value: null, matchMode: FilterMatchMode.IN },
    troPlacementStatus: { value: null, matchMode: FilterMatchMode.IN },
    stationCode: { value: null, matchMode: FilterMatchMode.IN },
    dayOfWeek: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    formatedBroadcastDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
    },
    analysisDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    startTime: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO }
      ]
    },
    endTime: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO }
      ]
    },
    programName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    broadcastListCount: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO }
      ]
    },
    monthlyCost: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO }
      ]
    }
  });

  return {
    textFilterMatchModes,
    numberFilterMatchModes,
    dateFilterMatchModes,
    filters
  };
};

interface UseValidatorReturnType {
  validTotalCost: Ref<string>;
}
export const useValidator = (
  isBudget: boolean,
  totalCost: Ref<string>,
  fixedProgramsCost: Ref<number>,
  maxCostForFixedStation: Ref<number>
): UseValidatorReturnType => {
  const validTotalCost = computed(() => {
    if (!isBudget) return '';
    if (totalCost.value === '') return '';
    const reach = Number(totalCost.value.replace(/,/g, ''));
    if (Number.isNaN(reach) || 100 > reach || reach > 9999999) {
      return VALIDATION_MESSAGE.invalidNumberWidth(100, 9999999);
    }
    if (reach < fixedProgramsCost.value) {
      return EXCEED_TOTAL_COST_BY_FIXED_PROGRAMS;
    }
    if (reach < maxCostForFixedStation.value) {
      return EXCEED_TOTAL_COST_BY_FIXED_STATIONS;
    }
    return '';
  });
  return {
    validTotalCost
  };
};
